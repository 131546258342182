import { SidebarMenuILink } from '@/interfaces';

type Props = {
    menu: SidebarMenuILink[];
    check: boolean;
    pathname: string;
    locale?: string;
};

const checkSidebarMenuItem = ({ menu, check, pathname, locale }: Props) => {
    let actualMenu: SidebarMenuILink[] = [];

    const news = {
        text: locale == 'ru' ? 'Новости и анонсы' : 'News and announcements',
        id: 'news',
        subLinks: [],
        href: '/rosneft/news'
    };

    if (check === false) {
        actualMenu = menu?.filter((el) => {
            return el.href !== '/rosneft/news';
        });
    } else {
        actualMenu = menu?.filter((el) => {
            return el.href !== '/rosneft/news';
        });
        if (pathname.includes('/rosneft')) actualMenu?.splice(9, 0, news);
    }

    return actualMenu;
};

export default checkSidebarMenuItem;
