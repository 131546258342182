import breakpoints from '@/style/breakpoints';
import { useEffect, useState } from 'react';
import { matchMediaBreakpoint } from '@/helpers';

/**
 * @description Определение соответствия переданного (breakpoints) значения ширине экрана
 */

const useIsMatches = (breakpoint: keyof typeof breakpoints = 'lg', initialState = false) => {
    const [isMatches, setIsMatches] = useState(initialState);

    useEffect(() => {
        if (typeof window === 'undefined') return;

        const mql = matchMediaBreakpoint(breakpoint);

        const testIsMobile = () => setIsMatches(mql.matches);

        testIsMobile();

        if (mql?.addEventListener) {
            mql.addEventListener('change', testIsMobile);
        } else {
            /** для поддержки IE */
            mql.addListener(testIsMobile);
        }

        return () => {
            if (mql?.removeEventListener) {
                mql.removeEventListener('change', testIsMobile);
            } else {
                mql.removeListener(testIsMobile);
            }
        };
    }, []);

    return isMatches;
};

export default useIsMatches;
