import styled from 'styled-components';
import { buttonStyle } from '@/style/mixins';
import { RectLinkProps, RectLinkViewType } from '@/components/ui/RectLink/RectLink';
import { Container as ModalBtn } from '@/components/ui/ModalBtn/ModalBtn.styled';
import { Colors } from '@/style/colors';

type BtnColors = Record<RectLinkViewType, Colors>;

const btnBgColors: BtnColors = {
    stroke: 'white',
    'fill-white': 'white',
    'fill-black': 'black',
    'fill-blue': 'blue'
};

const btnTextColors: BtnColors = {
    stroke: 'black',
    'fill-white': 'black',
    'fill-black': 'white',
    'fill-blue': 'white'
};

export const Container = styled.div<Required<Pick<RectLinkProps, 'type'>>>`
    text-align: center;
    ${({ type }) => buttonStyle(type === 'stroke', false, btnBgColors[type], btnTextColors[type])}
`;

export const ModalBtnContainer = styled.div<Required<Pick<RectLinkProps, 'type'>>>`
    text-align: center;

    ${ModalBtn} {
        ${({ type }) => buttonStyle(type === 'stroke', false, btnBgColors[type], btnTextColors[type])};
    }
`;
