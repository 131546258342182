import styled, { css } from 'styled-components';
import { allBreakpointValue, color, mediaBreakpointDown, mediaBreakpointUp, offset, vw } from '@/style/mixins';

export const LayoutInnerWrapper = styled.div<{ isTopOffset?: boolean }>`
    z-index: 1;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${color('white')};

    ${({ isTopOffset }) => isTopOffset && allBreakpointValue('padding-top', 90, 70, 60, 70, 70)};
`;

export const LayoutInnerRowWrapper = styled.div<{ isTopOffset?: boolean; noFhdSides?: boolean }>`
    flex-grow: 1;
    position: relative;
    display: flex;

    ${({ isTopOffset }) => isTopOffset && allBreakpointValue('padding-top', 90, 70, 60, 70, 70)};

    ${mediaBreakpointDown('xl')} {
        flex-wrap: wrap;
    }

    ${mediaBreakpointUp('fhd')} {
        ${({ noFhdSides }) =>
            !noFhdSides &&
            `
              padding-right: ${vw(80)};
              padding-left: ${vw(80)};
        `};
    }
`;

export const LayoutInnerContent = styled.div<{ spacingBetweenBlocks?: boolean }>`
    flex-grow: 1;
    overflow: hidden;
    width: 100%;

    ${mediaBreakpointUp('xl')} {
        border-left: solid 1px ${color('black', 0.1)};
    }

    ${({ spacingBetweenBlocks }) =>
        spacingBetweenBlocks &&
        css`
            > :not(.bgOn):not(:first-child) {
                ${offset('xxxl', 'margin-top')};
                ${offset('xxxl', 'margin-bottom')};
            }
        `}
`;
