import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPageBlock from '../ErrorPageBlock/ErrorPageBlock';
import LayoutInner from '../LayoutInner/LayoutInner';
import { Router } from 'next/router';

interface Props {
    router: Router;
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    router = this.props.router;

    constructor(props: Props) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error) {
        // Обновляем состояние, чтобы при следующем рендеринге отображался резервный пользовательский интерфейс
        return { hasError: true };
    }

    routeChangeComplete = () => this.setState({ hasError: false });

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Используем собственную службу регистрации ошибок здесь
        console.log('ErrorBoundary', { error, errorInfo });
    }

    componentDidMount() {
        this.router.events.on('routeChangeComplete', this.routeChangeComplete);
    }

    componentWillUnmount() {
        this.router.events.off('routeChangeComplete', this.routeChangeComplete);
    }

    render() {
        // Проверяем, есть ли ошибка
        if (this.state.hasError) {
            // Визуализирувем любой резервный пользовательский интерфейс
            return (
                <LayoutInner
                    meta={{ title: 'Application Error' }}
                    sidebar={{ header: { logo: '', href: '' }, menu: [], banner: null }}
                    errorPage
                    noFhdSides
                >
                    <ErrorPageBlock error="applicationError" onClick={() => location.reload()} />
                </LayoutInner>
            );
        }

        // Возвращаем дочерние компоненты в случае отсутствия ошибок
        return this.props.children;
    }
}

export default ErrorBoundary;
