import styled from 'styled-components';
import { mediaBreakpointDown } from '@/style/mixins';

export const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: calc(100vw - 80px - var(--scrollbar-width));
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    padding: 0;
    margin: 0;
    transform: translate(0);
    transition: transform 0.7s, visibility 0.7s, opacity 0.7s;
    opacity: 0;

    ${mediaBreakpointDown('xl')} {
        left: unset;
        right: 0;
    }

    &.hidesBtn {
        pointer-events: none;
        transform: translate(100%, 100%);
        visibility: hidden;
    }
`;

export const LoadingBtn = styled.button`
    width: 71%;
    height: 71%;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    border-radius: 20% 20% 0 20%;
    background-color: #008ede;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const DecorLine = styled.div`
    width: 50%;
    height: 4px;
    padding: 0;
    margin: 0;
    background-color: white;
    &::before {
        width: 50%;
        height: 100%;
        position: relative;
        background-color: white;
        display: block;
        top: 8px;
        content: '';
    }
    &::after {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: white;
        display: block;
        top: -12px;
        content: '';
    }
`;
