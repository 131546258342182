import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ICriterias, IQualification, IQualificationRequirements } from '@/interfaces';
import { getListingQualificationOrgsFetch, getListingQualificationCriteriasFetch, getListingQualificationCriteriasItem } from '@/lib/api';
import { AxiosResponse } from 'axios';

export type ListingQualificationOrgsState = {
    data: IQualification[];
    openQualificationId: number | null;
    criterias: ICriterias[] | null;
    criteriasInfo: IQualificationRequirements | null;
    total: number;
    totalPages: number;
    currentPage: number;
    limitPage: number;
    status: string;
    statusLoadMore: string;
    loadMore: boolean;
    extraFilters: object;
    extraUrlQuery: object;
};

export const getCriteriesItem = async (id: string) => {
    return (await getListingQualificationCriteriasFetch({ organization_id: id, limit: 1000 })).data.data;
};

export const getCriteriesSearchItem = async (params: any) => {
    return (await getListingQualificationCriteriasFetch(params)).data.data;
};

export const getCriteriesItemInfo = async (id: string) => {
    return (await getListingQualificationCriteriasItem(id)).data;
};

export const fetchQualificationOrgs = createAsyncThunk('listingQualification/fetchQualificationOrgs', async (params: any) => {
    const { data }: AxiosResponse = await getListingQualificationOrgsFetch(params);
    return data;
});

export const fetchMoreQualificationOrgs = createAsyncThunk('listingQualification/fetchMoreQualificationOrgs', async (params: any) => {
    const { data }: AxiosResponse = await getListingQualificationOrgsFetch(params);
    return data;
});

export const initialState: ListingQualificationOrgsState = {
    data: [],
    criterias: null,
    openQualificationId: null,
    criteriasInfo: null,
    total: 0,
    totalPages: 1,
    currentPage: 1,
    limitPage: 10,
    status: 'loading',
    statusLoadMore: 'success',
    loadMore: false,
    extraFilters: {},
    extraUrlQuery: {}
};

const listingQualificationOrgsSlice = createSlice({
    name: 'listingQualification',
    initialState,
    reducers: {
        setCurrentPage(state, action) {
            state.currentPage = action.payload.currentPage;
            state.loadMore = action.payload.loadMore;
        },
        setFilters(state, action) {
            state.currentPage = !action.payload.page ? initialState.currentPage : Number(action.payload.page);
        },
        setExtraFilters(state, action) {
            state.extraFilters = action.payload;
            state.currentPage = initialState.currentPage;
        },
        setExtraUrlQuery(state, action) {
            state.extraUrlQuery = action.payload;
        },
        setCriterias(state, action) {
            state.criterias = action.payload;
        },
        setOpenQualificationId(state, action) {
            state.openQualificationId = action.payload;
        },
        setCriteriasInfo(state, action) {
            state.criteriasInfo = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchQualificationOrgs.pending, (state) => {
            state.status = 'loading';
            state.data = [];
        });
        builder.addCase(fetchQualificationOrgs.fulfilled, (state, action) => {
            if (action.payload.error) {
                state.status = 'error';
                state.data = [];
                state.total = initialState.total;
                state.totalPages = initialState.totalPages;
            } else {
                state.data = action.payload.data;
                state.total = action.payload.total;
                state.totalPages = action.payload.totalPages;
                state.status = 'success';
            }
        });
        builder.addCase(fetchQualificationOrgs.rejected, (state) => {
            state.status = 'error';
            state.data = [];
        });
        builder.addCase(fetchMoreQualificationOrgs.pending, (state) => {
            state.statusLoadMore = 'loading';
            state.loadMore = false;
        });
        builder.addCase(fetchMoreQualificationOrgs.fulfilled, (state, action) => {
            state.data = [...state.data, ...action.payload.data];
            state.total = action.payload.total;
            state.totalPages = action.payload.totalPages;
            state.statusLoadMore = 'success';
        });
        builder.addCase(fetchMoreQualificationOrgs.rejected, (state) => {
            state.statusLoadMore = 'error';
        });
    }
});

export const { setCurrentPage, setFilters, setExtraFilters, setExtraUrlQuery, setCriterias, setCriteriasInfo, setOpenQualificationId } =
    listingQualificationOrgsSlice.actions;

export default listingQualificationOrgsSlice.reducer;
