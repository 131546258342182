import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const Market: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/market/service': SAMPLES.SERVICE_BLOCKS_INTERACTION_TAGS, //работает
    '/market/service/e-signature': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/market/service/icwe': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/market/service/accompany-tenders': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/market/service/placement-production': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/market/service/edo': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/market/service/credit': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/market/customers/help': { ...SAMPLES.CLIENTS_BLOCK_CARDS_SAMPLES, ...SAMPLES.CLIENT_CENTER_FORMS_SAMPLES },
    '/market/suppliers/help': { ...SAMPLES.CLIENTS_BLOCK_CARDS_SAMPLES, ...SAMPLES.CLIENT_CENTER_FORMS_SAMPLES }
};
