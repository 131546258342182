import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IProcedureShort } from '@/interfaces';
import { getLotsFetch, getPropertyTypesFetch } from '@/lib/api';
import { AxiosResponse } from 'axios';

const LIMIT = 15;

type ListingLotsState = {
    data: IProcedureShort[];
    propertyTypes: any;
    total: number;
    totalPages: number;
    currentPage: number;
    limitPage: number;
    viewType: number;
    sortProperty: string;
    status: string;
    statusPropertyTypes: string;
    statusLoadMore: string;
    loadMore: boolean;
    extraFilters: object;
    extraUrlQuery: object;
    sectionsCodes: string | string[];
    viewTag: number;
    title: string | null;
    code: string;
    id: number | null;
    activeCheckboxes: object;
};

export const fetchPropertyTypes = createAsyncThunk('listingLots/fetchPropertyTypes', async (parentId?: number) => {
    const { data }: AxiosResponse = await getPropertyTypesFetch('property-types', { parent: parentId, limit: 200 });
    return data;
});

export const fetchLots = createAsyncThunk('listingLots/fetchLots', async (params: any) => {
    const { data }: AxiosResponse = await getLotsFetch({ ...params, limit: LIMIT }, true);
    return data;
});

export const fetchMoreLots = createAsyncThunk('listingLots/fetchMoreLots', async (params: any) => {
    const { data }: AxiosResponse = await getLotsFetch({ ...params, limit: LIMIT }, true);
    return data;
});

export const initialState: ListingLotsState = {
    data: [],
    propertyTypes: [],
    total: 0,
    totalPages: 1,
    currentPage: 1,
    limitPage: LIMIT,
    viewType: 0,
    sortProperty: 'actual',
    status: 'loading',
    statusPropertyTypes: 'loading',
    statusLoadMore: 'success',
    loadMore: false,
    extraFilters: {},
    extraUrlQuery: {},
    sectionsCodes: [],
    viewTag: -1,
    title: null,
    code: '',
    id: null,
    activeCheckboxes: {}
};

const listingLotsSlice = createSlice({
    name: 'listingLots',
    initialState,
    reducers: {
        setViewType(state, action) {
            state.viewType = action.payload;
        },
        setSortType(state, action) {
            state.sortProperty = action.payload;
        },
        setPropertyTypeId(state, action) {
            state.id = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload.currentPage;
            state.loadMore = action.payload.loadMore;
        },
        setFilters(state, action) {
            state.currentPage = !action.payload.page ? initialState.currentPage : Number(action.payload.page);
            state.sortProperty = !action.payload.sort ? initialState.sortProperty : action.payload.sort;

            state.sectionsCodes = !action.payload['sectionsCodes[]']
                ? initialState.sectionsCodes
                : action.payload['sectionsCodes[]'].split(';');
            state.code = !action.payload.code ? initialState.code : action.payload.code;
            state.id = !action.payload.id ? initialState.id : action.payload.id;
            state.title = !action.payload.title ? initialState.title : action.payload.title;
            if (state.viewTag === initialState.viewTag) {
                state.viewTag = !action.payload.tag ? initialState.viewTag : Number(action.payload.tag);
            }
        },
        setExtraFilters(state, action) {
            state.extraFilters = action.payload;
            state.currentPage = initialState.currentPage;
        },
        setExtraUrlQuery(state, action) {
            state.extraUrlQuery = action.payload;
        },
        setViewTag(state, action) {
            state.viewTag = action.payload;
        },
        setNameCategory(state, action) {
            state.title = action.payload;
        },
        setCode(state, action) {
            state.code = action.payload;
        },
        setSectionsCodes(state, action) {
            state.sectionsCodes = action.payload;
        },
        setActiveCheckboxes(state, action) {
            state.activeCheckboxes = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPropertyTypes.pending, (state) => {
            state.statusPropertyTypes = 'loading';
            state.propertyTypes = [];
        });
        builder.addCase(fetchPropertyTypes.fulfilled, (state, action) => {
            if (action.payload.error) {
                state.statusPropertyTypes = 'error';
                state.propertyTypes = [];
            } else {
                state.statusPropertyTypes = 'success';
                state.propertyTypes = action.payload.data;
            }
        });
        builder.addCase(fetchPropertyTypes.rejected, (state) => {
            state.propertyTypes = [];
        });
        builder.addCase(fetchLots.pending, (state) => {
            state.status = 'loading';
            state.data = [];
        });
        builder.addCase(fetchLots.fulfilled, (state, action) => {
            if (action.payload.error) {
                state.status = 'error';
                state.data = [];
                state.total = initialState.total;
                state.totalPages = initialState.totalPages;
            } else {
                state.data = action.payload.data;
                state.total = action.payload.total;
                state.totalPages = action.payload.totalPages;
                state.status = 'success';
            }
        });
        builder.addCase(fetchLots.rejected, (state) => {
            state.status = 'error';
            state.data = [];
        });
        builder.addCase(fetchMoreLots.pending, (state) => {
            state.statusLoadMore = 'loading';
            state.loadMore = false;
        });
        builder.addCase(fetchMoreLots.fulfilled, (state, action) => {
            state.data = [...state.data, ...action.payload.data];
            state.total = action.payload.total;
            state.totalPages = action.payload.totalPages;
            state.statusLoadMore = 'success';
        });
        builder.addCase(fetchMoreLots.rejected, (state) => {
            state.statusLoadMore = 'error';
        });
    }
});

export const {
    setViewType,
    setSortType,
    setCurrentPage,
    setFilters,
    setExtraFilters,
    setExtraUrlQuery,
    setViewTag,
    setNameCategory,
    setCode,
    setPropertyTypeId,
    setSectionsCodes,
    setActiveCheckboxes
} = listingLotsSlice.actions;

export default listingLotsSlice.reducer;
