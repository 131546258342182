import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pageBannersStateTypes } from './types';

const initialState: pageBannersStateTypes = {
    headerBanners: undefined,
    sideBanners: undefined
};

const pageBannersSlice = createSlice({
    name: 'banners',
    initialState,
    reducers: {
        setHeaderBanners(state, action: PayloadAction<any>) {
            state.headerBanners = action.payload;
        },
        setSideBanners(state, action: PayloadAction<any>) {
            state.sideBanners = action.payload;
        }
    }
});

export const { setHeaderBanners, setSideBanners } = pageBannersSlice.actions;

export default pageBannersSlice.reducer;
