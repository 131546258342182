import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const Rosneft: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/rosneft': {
        ...SAMPLES.SERVICE_BLOCKS_INTERACTION_CARDS
    },
    '/rosneft/edinoe_okno/predlozheniya_o_sotrudnichestve': SAMPLES.ROSNEFT_PARTNERSHIP_FORMS_SAMPLES,
    '/rosneft/edinoe_okno/ideya_innovacionnogo_proekta': SAMPLES.ROSNEFT_FORMS_SAMPLES,
    '/rosneft/edinoe_okno/otkrytye_zaprosy': SAMPLES.ROSNEFT_FORMS_SAMPLES,
    '/rosneft/importozameshchenie/op_ispytaniya': SAMPLES.ROSNEFT_FORMS_SAMPLES
};
