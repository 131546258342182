import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const Sale: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/sale': { ...SAMPLES.PROPERTY_SALE_INTERACTION_TAGS, ...SAMPLES.CALLBACK_FORMS_SAMPLES }, //работает
    '/sale/service/e-signature': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/sale/service/edo': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/sale/service/134': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/sale/service': SAMPLES.SERVICE_BLOCKS_INTERACTION_TAGS,
    '/sale/buyers/help': SAMPLES.CLIENT_CENTER_FORMS_SAMPLES,
    '/sale/sellers/help': SAMPLES.CLIENT_CENTER_FORMS_SAMPLES
};
