import styled, { css } from 'styled-components';

function createCSS(
    sources: {
        src: string;
        breakpoint: number;
    }[]
) {
    let styles = '';

    for (let i = sources?.length - 1; i >= 0; i--) {
        styles += `
           @media(min-width: ${sources[i].breakpoint}px) {
            background-image: url(${sources[i].src});
        }
     `;
    }

    return css`
        ${styles}
    `;
}

export const Container = styled.picture<{
    isIE11: boolean;
    src: string;
    sources?: {
        src: string;
        breakpoint: number;
    }[];
}>`
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    ${({ sources, isIE11 }) => sources && isIE11 && createCSS(sources)};

    ${({ isIE11, src }) =>
        isIE11 &&
        src &&
        `
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        
         background-image: url(${src});
    `};

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        ${({ isIE11 }) => isIE11 && 'opacity: 0'};
    }
`;
