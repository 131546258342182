import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalState } from './types';

const initialState: ModalState = {
    id: '',
    open: false,
    fromFooter: false,
    altDescription: {
        text: ''
    },
    optionalId: ''
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setModal(state, action: PayloadAction<ModalState>) {
            return { ...state, ...action.payload };
        }
    }
});

export const { setModal } = modalSlice.actions;

export default modalSlice.reducer;
