import styled from 'styled-components';
import { font, mediaBreakpointDown, mediaBreakpointUp, vw, allBreakpointValue, hover, color } from '@/style/mixins';
import variables from '@/style/variables';

export const Container = styled.div`
    position: relative;
    display: flex;

    ${allBreakpointValue('margin-top', 35, 45, 40)};
    ${allBreakpointValue('padding-top', 22, 18, 16, 24, 40)};
    ${allBreakpointValue('padding-bottom', 22, 18, 16, 24, 40)};

    ${mediaBreakpointDown('md')} {
        flex-wrap: wrap;
    }

    p {
        ${font('text1-b')};
        margin-bottom: 16px;
        ${allBreakpointValue('margin-bottom', 18, 18, 16)};
    }

    a,
    button {
        ${font('text3-l')};
        color: inherit;

        opacity: 0.8;
        transition: opacity 0.3s ease-in-out;

        ${hover(` opacity: 1`)};
    }

    ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style: none;

        ${mediaBreakpointUp('xl')} {
            flex-direction: row;
        }

        ${mediaBreakpointUp('md')} {
            margin-top: auto;
        }
    }

    li {
        display: flex;

        &:not(:first-child) {
            ${allBreakpointValue('margin-left', 36, 30, 26)};
        }

        ${mediaBreakpointUp('xl')} {
            margin-top: 0;
            white-space: nowrap;
        }

        ${mediaBreakpointDown('xl')} {
            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ${mediaBreakpointDown('md')} {
            width: 50%;

            &:nth-child(2) {
                padding-left: 24px;
            }

            &:nth-child(3) {
                width: 100%;
                flex-direction: row;
                padding-left: 0;
                margin-top: 32px;

                ul {
                    flex-direction: row;
                    width: 100%;
                }

                li {
                    width: 50%;
                    margin-top: 0;

                    &:nth-child(2) {
                        padding-left: 24px;
                    }
                }
            }
        }

        ${mediaBreakpointDown('xl')} {
            &:nth-child(2) {
                padding-left: ${vw(48, 768)};
            }

            &:nth-child(3) {
                padding-left: ${vw(15, 768)};
            }
        }

        ${mediaBreakpointDown('md')} {
            &:nth-child(3) {
                padding-left: 0;
            }
        }

        ${mediaBreakpointUp('md')} {
            width: calc(100% / 3);
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 1px;
        background-color: ${color('white', 0.1)};
        left: ${vw(-variables.offset.mobile, 375)};
        right: ${vw(-variables.offset.mobile, 375)};

        ${mediaBreakpointUp('md')} {
            left: ${vw(-variables.offset.tablet, 768)};
            right: ${vw(-variables.offset.tablet, 768)};
        }

        ${mediaBreakpointUp('xl')} {
            left: 0;
            right: 0;
        }
    }
`;
