import dynamic from 'next/dynamic';
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Container, HeaderLogin, HeaderLogo, HeaderMenu, HeaderSandwichBtn, HeaderInnerWrapper, HeaderBackLayer } from './Header.styled';
import Logo from '@/icons/logo.svg';
import LogoEn from '@/icons/logo-en.svg';
import House from '@/icons/house.svg';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useTranslation from '@/hooks/useTranslation';
import { MenuILink } from '@/interfaces';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import { setSandwich, switchSandwich } from '@/redux/sandwich/sandwichSlice';
import { setModal } from '@/redux/modal/modalSlice';
import { useInView } from 'react-intersection-observer';
import { WidgetProps } from '@/components/blocks/Widget/Widget';
import { sendMetrik } from '@/helpers';
import { HeaderLang } from './HeaderLang/HeaderLang';

const HeaderLinks = dynamic(() => import('../../blocks/HeaderLinks/HeaderLinks'));
const ModalWrapper = dynamic(() => import('../../common/ModalWrapper/ModalWrapper'));
const ModalEnter = dynamic(() => import('../../blocks/ModalEnter/ModalEnter'));
const ImportantMessage = dynamic(() => import('../../blocks/ImportantMessage/ImportantMessage'));

const mapStateToProps = ({ sandwich }: RootState) => ({
    sandwich
});
const mapDispatchToProps = { setSandwich, switchSandwich, setModal };
const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;

export interface HeaderProps {
    isTablet?: boolean;
    widgets?: WidgetProps[] | null;
    isFirstBgFill?: boolean;
}

const Header = React.forwardRef(
    (
        { isTablet, sandwich, setSandwich, switchSandwich, setModal, widgets, isFirstBgFill }: HeaderProps & PropsFromRedux,
        forwardedRef: ForwardedRef<HTMLDivElement | null>
    ) => {
        const { ref, inView, entry } = useInView();
        const { asPath, locale } = useRouter();
        const { t } = useTranslation('common');
        const [menu] = useState<MenuILink[] | []>(() => t('menu', { returnObjects: true }));
        const refContainer = useRef<HTMLDivElement | null>(null);
        const refLayer = useRef<HTMLDivElement | null>(null);

        useImperativeHandle(forwardedRef, () => refContainer.current as HTMLDivElement);

        useEffect(() => {
            if (!isTablet) setSandwich(false);
        }, [isTablet, setSandwich]);

        useEffect(() => {
            if (!refContainer.current) return;

            const isOver = sandwich || (!inView && entry);

            refContainer.current?.classList[isOver ? 'add' : 'remove']('_overBg');
            refContainer.current?.classList[sandwich ? 'add' : 'remove']('_openSandwich');
        }, [sandwich, inView, entry]);

        return (
            <>
                <div
                    ref={ref}
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        top: '1px',
                        left: '20px',
                        pointerEvents: 'none'
                    }}
                />

                {!isTablet && <HeaderBackLayer ref={refLayer} />}

                <Container ref={refContainer} className={`${!!isFirstBgFill ? '_fill' : ''}`}>
                    <ImportantMessage />

                    {asPath === '/' ? (
                        <HeaderLogo>{locale === 'ru' ? <Logo /> : <LogoEn />}</HeaderLogo>
                    ) : (
                        <Link href={'/'} passHref prefetch={false}>
                            <HeaderLogo>{locale === 'ru' ? <Logo /> : <LogoEn />}</HeaderLogo>
                        </Link>
                    )}

                    {menu.length && (
                        <HeaderMenu>
                            <HeaderLinks layer={refLayer.current} menu={menu} widgets={widgets} parent={refContainer.current} />
                        </HeaderMenu>
                    )}

                    <HeaderInnerWrapper>
                        <HeaderLang />
                        <HeaderLogin
                            onClick={() => {
                                setModal({ id: 'modalEnter', open: true });
                                sendMetrik('menu-click-sign-in', 'auth', {});
                            }}
                        >
                            <House /> {t('login')}
                        </HeaderLogin>
                    </HeaderInnerWrapper>

                    {isTablet && <HeaderSandwichBtn onClick={() => switchSandwich()} />}

                    <ModalWrapper id={'modalEnter'} title={t('modalEnter.title')} description={t('modalEnter.description')} isWide>
                        <ModalEnter
                            sections={t('modalEnter.sections', { returnObjects: true })}
                            description={t('modalEnter.detailedDescription')}
                        />
                    </ModalWrapper>
                </Container>
            </>
        );
    }
);

Header.displayName = 'Header';

export default connector(Header);
