// Если возникнет необходимость менять dev- на stand- или наоборот то можно использовать эту версию:
// Пример использования: replacement('dev-', 'stage-');
/* const replacement = (href: string, deleteStand: string, insertStand: string): string => {
    if (href.includes(insertStand)) {
        return href;
    }

    return href.replace(deleteStand, '').replace(/(https:\/\/)(.*?)(\/|$)/, `$1${insertStand}$2$3`);
};
 */

const replacement = (href: string, stand: string): string => {
    if (href.includes(stand)) {
        return href;
    }

    return href.replace('44.tektorg.ru', '44fz.tektorg.ru').replace(/(https:\/\/)(.*?)(\/|$)/, `$1${stand}$2$3`);
};

export const redirectToStand = (href: string, currentURL: string): string => {
    if (currentURL !== '' && href.includes('tektorg.ru')) {
        if (currentURL.includes('stage-')) return replacement(href, 'stage-');
        if (currentURL.includes('dev-')) return replacement(href, 'dev-');
    }

    return href;
};
