import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IProcedureOther, IProcedureShort } from '@/interfaces';
import { getProceduresFetch } from '@/lib/api';
import { AxiosResponse } from 'axios';

const LIMIT = 15;

type ListingProceduresState = {
    data: IProcedureShort[];
    other: IProcedureOther[];
    total: number;
    totalPages: number;
    currentPage: number;
    limitPage: number;
    viewType: number;
    sortProperty: string;
    status: string;
    errorCode: number | null;
    statusLoadMore: string;
    loadMore: boolean;
    extraFilters: object;
    extraUrlQuery: object;
    sectionsCodes: object;
    regionCodes: object;
    organizerCodes: object;
    industrySectors: object;
    activeCheckboxes: object;
};

export const fetchProcedures = createAsyncThunk('listingProcedures/fetchProcedures', async (params: any) => {
    const { data }: AxiosResponse = await getProceduresFetch({ ...params, limit: LIMIT });
    return data;
});

export const fetchMoreProcedures = createAsyncThunk('listingProcedures/fetchMoreProcedures', async (params: any) => {
    const { data }: AxiosResponse = await getProceduresFetch({ ...params, limit: LIMIT });
    return data;
});

export const initialState: ListingProceduresState = {
    data: [],
    other: [],
    total: 0,
    totalPages: 1,
    currentPage: 1,
    limitPage: LIMIT,
    viewType: 0,
    sortProperty: 'actual',
    status: 'loading',
    errorCode: null,
    statusLoadMore: 'success',
    loadMore: false,
    extraFilters: {},
    extraUrlQuery: {},
    sectionsCodes: {},
    regionCodes: {},
    organizerCodes: {},
    industrySectors: {},
    activeCheckboxes: {}
};

const listingProceduresSlice = createSlice({
    name: 'listingProcedures',
    initialState,
    reducers: {
        setViewType(state, action) {
            state.viewType = action.payload;
        },
        setSortType(state, action) {
            state.sortProperty = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload.currentPage;
            state.loadMore = action.payload.loadMore;
        },
        setFilters(state, action) {
            state.currentPage = !action.payload.page ? initialState.currentPage : Number(action.payload.page);
            state.sortProperty = !action.payload.sort ? initialState.sortProperty : action.payload.sort;
        },
        setExtraFilters(state, action) {
            state.extraFilters = action.payload;
            state.currentPage = initialState.currentPage;
        },
        setExtraUrlQuery(state, action) {
            state.extraUrlQuery = action.payload;
        },
        setRegions(state, action) {
            state.regionCodes = action.payload;
        },
        setOrganizers(state, action) {
            state.organizerCodes = action.payload;
        },
        setSections(state, action) {
            state.sectionsCodes = action.payload;
        },
        setIndustry(state, action) {
            state.industrySectors = action.payload;
        },
        setActiveCheckboxes(state, action) {
            state.activeCheckboxes = action.payload;
        },
        reset() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProcedures.pending, (state) => {
            state.status = 'loading';
            state.statusLoadMore = 'success';
            state.errorCode = null;
            state.data = [];
            state.other = [];
        });
        builder.addCase(fetchProcedures.fulfilled, (state, action) => {
            if (!action.payload.data?.length) {
                state.status = 'error';
                state.data = [];
                state.total = initialState.total;
                state.totalPages = initialState.totalPages;
                state.other = action.payload.other || [];
            } else {
                state.data = action.payload.data;
                state.total = action.payload.total;
                state.totalPages = action.payload.totalPages;
                state.status = 'success';
                state.other = action.payload.other || [];
            }
        });
        builder.addCase(fetchProcedures.rejected, (state, action) => {
            state.status = 'error';
            state.errorCode = action.error.message === 'Request failed with status code 504' ? 504 : 400;
            state.data = [];
            state.total = initialState.total;
            state.totalPages = initialState.totalPages;
            state.other = [];
        });

        builder.addCase(fetchMoreProcedures.pending, (state) => {
            state.statusLoadMore = 'loading';
            state.loadMore = false;
            state.errorCode = null;
        });
        builder.addCase(fetchMoreProcedures.fulfilled, (state, action) => {
            state.data = [...state.data, ...action.payload.data];
            state.total = action.payload.total;
            state.totalPages = action.payload.totalPages;
            state.statusLoadMore = 'success';
        });
        builder.addCase(fetchMoreProcedures.rejected, (state, action) => {
            state.statusLoadMore = 'error';
            state.errorCode = action.error.message === 'Request failed with status code 504' ? 504 : 400;
        });
    }
});

export const {
    setViewType,
    setSortType,
    setCurrentPage,
    setFilters,
    setExtraFilters,
    setExtraUrlQuery,
    setRegions,
    setOrganizers,
    setSections,
    setIndustry,
    setActiveCheckboxes,
    reset
} = listingProceduresSlice.actions;

export default listingProceduresSlice.reducer;
