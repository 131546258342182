import React, { useEffect, useState } from 'react';
import { Container, SpoilerBody, SpoilerHead, SpoilerHeadBtn, SpoilerHeadText } from './Spoiler.styled';
import { BaseUI } from '@/interfaces';
import Arrow from '@/icons/arrow.svg';

interface SpoilerProps extends BaseUI {
    header: React.ReactNode;
    headerBtn?: boolean;
    headText?: string;
    className?: string;
    arrowBtn?: boolean;
    clickHandler?: () => void;
    activeIndex?: boolean;
    outsideIsOpen?: boolean;
    onClickFunc?: React.Dispatch<React.SetStateAction<null | string>>;
    id?: string;
    metrika?: React.MouseEventHandler;
}

const Spoiler: React.FC<SpoilerProps> = ({
    header,
    headerBtn,
    headText,
    children,
    className,
    arrowBtn,
    clickHandler,
    activeIndex = false,
    outsideIsOpen,
    onClickFunc,
    id,
    metrika
}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (outsideIsOpen !== undefined) setIsOpen(outsideIsOpen);
    }, [outsideIsOpen]);

    useEffect(() => {
        setIsOpen(activeIndex);
    }, [activeIndex]);

    return (
        <Container className={`${className ? className : ''} ${isOpen ? '_is-open' : ''}`}>
            <SpoilerHead
                className={isOpen ? '_open' : ''}
                onClick={(e) => {
                    clickHandler ? clickHandler() : setIsOpen(!isOpen);
                    if (onClickFunc && id) onClickFunc(id);
                    if (!isOpen && metrika) metrika(e);
                }}
            >
                {header}

                {headerBtn && (
                    <SpoilerHeadBtn arrowIcon={arrowBtn} isOpen={isOpen}>
                        {headText && <SpoilerHeadText dangerouslySetInnerHTML={{ __html: headText }} />}
                        {arrowBtn && <Arrow />}
                    </SpoilerHeadBtn>
                )}
            </SpoilerHead>
            <SpoilerBody isOpen={isOpen}>{children}</SpoilerBody>
        </Container>
    );
};

export default Spoiler;
