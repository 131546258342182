import React, { useState, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { detect } from 'detect-browser';
import { LayoutInnerWrapper, LayoutInnerContent, LayoutInnerRowWrapper } from './LayoutInner.styled';
import Header, { HeaderProps } from '../Header/Header';
import Footer from '../Footer/Footer';
import { BasePageProps } from '@/interfaces';
import useDebounce from '@/hooks/useDebounce';
import useIsIE11 from '@/hooks/useIsIE11';
import ResizeObserver from 'resize-observer-polyfill';
import Sidebar, { SidebarProps } from '@/components/common/Sidebar/Sidebar';
import { LeadImgProps } from '@/components/blocks/LeadImg/LeadImg';
import breakpoints from '@/style/breakpoints';
import useIsMobile from '@/hooks/useIsMobile';

import { DefaultTheme, ThemeProvider } from 'styled-components';
import { baseTheme } from '@/style/themes';

import { useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import ScrollTopButton from '../ScrollTopButton/ScrollTopButton';
import useImportantMessageHeight from '@/hooks/useImportantMessageHeight';
import { WebimLoadingBtn } from '../WebimLoadingBtn/WebimLoadingBtn';

const Sandwich = dynamic(() => import('../Sandwich/Sandwich'));
const LeadImg = dynamic(() => import('@/components/blocks/LeadImg/LeadImg'));
const SidebarMobileMenu = dynamic(() => import('@/components/blocks/SidebarMobileMenu/SidebarMobileMenu'));

interface LayoutProps extends BasePageProps {
    theme?: DefaultTheme;
    sidebar: SidebarProps;
    leadImg?: LeadImgProps;
    isFirstBgFill?: HeaderProps['isFirstBgFill'];
    propsFooterForm?: string;
    modalTitle?: string;
    errorPage?: boolean;
    sidebarPath?: string;
    modalID?: string;
    noFhdSides?: boolean;
    spacingBetweenBlocks?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
    children,
    meta,
    theme = baseTheme,
    sidebar,
    leadImg,
    isFirstBgFill = false,
    propsFooterForm,
    errorPage = false,
    modalTitle,
    modalID,
    sidebarPath,
    noFhdSides = false,
    spacingBetweenBlocks = false
}) => {
    const isIE11 = useIsIE11();
    const [value, setValue] = useState({ w: 0, h: 0 });
    const [mountIE, setIEMounted] = useState(true);
    const [isTablet, setIsTablet] = useState(false);
    const debouncedValue = useDebounce(value, 100);
    const headerRef = useRef<HTMLDivElement | null>(null);
    const leadImgRef = useRef<HTMLElement | null>(null);
    const innerWrapperRef = useRef<HTMLDivElement | null>(null);
    const headerWidgets = useSelector((state: RootState) => state.banners.headerBanners);
    const isMobile = useIsMobile();
    const layOutOffset = useImportantMessageHeight(headerRef);

    useEffect(() => {
        if (detect()?.os === 'iOS') document.documentElement.classList.add('_is-ios');

        const resizeHandler = () => {
            setValue((prev) => ({ ...prev, w: window.innerWidth, h: window.innerHeight }));

            if (!window) return;
            const { innerWidth } = window;

            setIsTablet(innerWidth < breakpoints['xl']);
        };

        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver?.observe(document.documentElement);
        resizeHandler();

        return () => resizeObserver?.disconnect();
    }, []);

    useEffect(() => {
        const h = `${window.innerHeight * 0.01}px`;
        document.documentElement.style.setProperty('--vh', h);
    }, [debouncedValue]);

    useEffect(() => {
        let t: NodeJS.Timeout;
        if (isIE11) {
            setIEMounted(false);

            t = setTimeout(() => {
                document.documentElement.classList.add('_is-ie');
                setIEMounted(true);
            }, 10);
        }
        return () => clearTimeout(t);
    }, [isIE11]);

    return (
        <ThemeProvider theme={theme}>
            <Head>
                <title>{meta.title}</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="description" content={meta.description} />
                <meta name="keywords" content={meta.keywords} />
                <meta name="og:title" content={meta.title} />
                <meta name="og:description" content={meta.description} />
                {meta.image && <meta property="og:image" content={meta.image} />}
                {meta.noindex && <meta name="robots" content="noindex" />}
                {meta.canonical && <link rel="canonical" href={meta.canonical} />}
            </Head>

            {mountIE && (
                <>
                    <Header ref={headerRef} widgets={headerWidgets ?? null} isTablet={isTablet} isFirstBgFill={isFirstBgFill || !leadImg} />

                    {isTablet && <Sandwich widgets={headerWidgets ?? null} layOutOffset={layOutOffset} />}

                    <ScrollTopButton />

                    {!(typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.DOCUMENT_NODE) && <WebimLoadingBtn />}

                    <div style={{ paddingTop: `${layOutOffset}px` }}>
                        {leadImg && (
                            <section ref={leadImgRef} className={'leadImgWrapper'}>
                                <LeadImg {...leadImg} />
                            </section>
                        )}
                        <LayoutInnerWrapper ref={innerWrapperRef} isTopOffset={!leadImg}>
                            <LayoutInnerRowWrapper noFhdSides={noFhdSides}>
                                {!errorPage && <Sidebar {...sidebar} sidebarPath={sidebarPath} />}
                                <LayoutInnerContent spacingBetweenBlocks={spacingBetweenBlocks}>{children}</LayoutInnerContent>
                                {!errorPage && isMobile && <SidebarMobileMenu {...sidebar} />}
                            </LayoutInnerRowWrapper>
                            <Footer propsFooterForm={propsFooterForm} modalTitle={modalTitle} modalID={modalID} />
                        </LayoutInnerWrapper>
                    </div>
                </>
            )}
        </ThemeProvider>
    );
};

export default Layout;
