import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const Tenders: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/tenders/help': SAMPLES.CLIENTS_BLOCK_CARDS_SAMPLES,
    '/tenders/service': SAMPLES.SERVICE_BLOCKS_INTERACTION_TAGS, //работает
    '/tenders/service/e-signature': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/tenders/service/accompany-tenders': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/tenders/service/edo': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/tenders/service/credit': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/tenders': SAMPLES.UVS_BLOCK_CARDS_SAMPLES
};
