import styled from 'styled-components';
import { allBreakpointValue, color, font, mediaBreakpointDown, mediaBreakpointUp, offset, vw } from '@/style/mixins';

type HeaderContainerT = {
    overBg?: boolean;
    lineColor?: number;
};

export const Container = styled.header<HeaderContainerT>`
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    color: ${color('white')};
    background-color: transparent;

    transition: background-color 0.3s ease-in-out;

    padding: ${vw(24, 375)} ${vw(20, 375)} ${vw(12, 375)};

    ${mediaBreakpointDown('md')} {
        @media (max-height: 400px) {
            padding-top: 24px;
        }
    }

    ${mediaBreakpointUp('md')} {
        padding: ${vw(24, 768)} ${vw(40, 768)} ${vw(12, 768)};
    }

    ${mediaBreakpointUp('xl')} {
        padding: ${vw(14, 1280)} ${vw(26, 1280)} ${vw(9, 1280)};
    }

    ${mediaBreakpointUp('xxl')} {
        padding: ${vw(15, 1440)} ${vw(30, 1440)} ${vw(10, 1440)};
    }

    ${mediaBreakpointUp('fhd')} {
        padding: ${vw(21)} ${vw(120)} ${vw(17)};
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: 0;

        border-top: thin solid ${color('white')};
        opacity: 0.1;
        transition: border 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    ${mediaBreakpointUp('xl')} {
        &:hover {
            color: ${color('black')};
            background-color: ${color('white')};

            &:after {
                border-top: ${({ theme }) => (theme.headerBorder !== 'white' && theme.headerBorder !== 'currentColor' ? '4px' : 'thin')}
                    solid ${({ theme }) => (theme.headerBorder !== 'currentColor' ? color(theme.headerBorder) : 'currentColor')};
                opacity: ${({ theme }) => (theme.headerBorder !== 'white' && theme.headerBorder !== 'currentColor' ? '1' : '0.1')};
            }

            .logo-r_t {
                stroke: ${color('blue')};
                fill: ${color('blue')};
            }
        }
    }

    &._overBg,
    &._fill,
    &._subm-show {
        color: ${color('black')};
        background-color: ${color('white')};

        &:after {
            opacity: ${({ theme }) => (theme.headerBorder !== 'white' && theme.headerBorder !== 'currentColor' ? '1' : '0.1')};

            border-top: ${({ theme }) => (theme.headerBorder !== 'white' && theme.headerBorder !== 'currentColor' ? '4px' : 'thin')} solid
                ${({ theme }) => (theme.headerBorder !== 'currentColor' ? color(theme.headerBorder) : 'currentColor')};
        }
    }

    svg {
        display: inline-block;
        pointer-events: none;
    }
`;

export const HeaderLogo = styled.a`
    svg {
        fill: currentColor;
        transition: color 0.3s ease-in-out;

        width: auto;
        height: 100%;

        .logo-r_t {
            stroke: currentColor;
            fill: transparent;
            transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;

            ._overBg &,
            ._fill &,
            ._subm-show & {
                stroke: ${color('blue')};
                fill: ${color('blue')};
            }
        }
    }

    flex-shrink: 0;
    margin-right: auto;
    ${allBreakpointValue('width', 214, 160, 141, 154, 137)};
    ${allBreakpointValue('height', 33, 25, 22, 24, 21)};

    ${mediaBreakpointDown('md')} {
        @media (max-height: 400px) {
            max-width: 214px;
            max-height: 33px;
        }
    }

    ${mediaBreakpointUp('xl')} {
        ${offset('xxl', 'margin-right')};
    }
`;

export const HeaderLogin = styled.div`
    padding: 0;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    vertical-align: middle;
    line-height: 1;
    color: currentColor;
    transition: color 0.3s ease-in-out;

    ._is-ie & {
        display: block;
        padding-bottom: 0.15em;
    }

    ${mediaBreakpointUp('xl')} {
        margin-left: 20px;
    }

    svg {
        display: inline-block;
        fill: currentColor;
        width: 12px;
        height: 11px;
        vertical-align: middle;
        line-height: 1;
        margin-right: 9px;

        ._is-ie & {
            margin-bottom: 0.15em;
        }

        ${mediaBreakpointUp('xl')} {
            margin-right: 5px;
        }

        ${mediaBreakpointUp('xxl')} {
            width: 14px;
            height: 12px;
        }

        ${mediaBreakpointUp('fhd')} {
            width: ${vw(18)};
            height: ${vw(16)};
            margin-right: 8px;
        }
    }
`;

export const HeaderMenu = styled.nav`
    display: none;
    align-items: center;
    color: currentColor;

    ${mediaBreakpointUp('lg')} {
        display: flex;
    }
`;

export const HeaderSandwichBtn = styled.button`
    background: transparent;
    padding: 0;
    color: currentColor;
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ._is-ie & {
        margin-bottom: 0.15em;
    }

    ${mediaBreakpointUp('md')} {
        margin-left: ${vw(30, 768)};
    }

    &:before,
    &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: currentColor;
        transition: transform 0.3s ease-in-out;
        position: absolute;
        top: calc(50% - 1px);
    }

    &:before {
        transform: translateY(-4px);
        ._openSandwich & {
            transform: translateY(0) rotate(45deg);
        }
    }

    &:after {
        transform: translateY(4px);
        ._openSandwich & {
            transform: translateY(0) rotate(-45deg);
        }
    }
`;
export const HeaderInnerWrapper = styled.div`
    position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 16px ${vw(20, 375)};

    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    border-bottom: thin solid ${color('black', 0.1)};

    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;

    background-color: transparent;
    opacity: 0;
    visibility: hidden;

    ._openSandwich & {
        background-color: ${color('white')};
        opacity: 1;
        visibility: visible;
    }

    ${font('menu')};

    ${mediaBreakpointDown('xl')} {
        ${font('text2-b')};
    }

    ${mediaBreakpointUp('md')} {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        transform: none;
        border-bottom: none;
        flex-direction: row;
        justify-content: flex-start;
        padding: 0;
        margin-left: auto;
        opacity: 1;
        visibility: visible;
        background-color: transparent;
    }
`;

export const HeaderBackLayer = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &._show {
        opacity: 1;
        visibility: visible;
    }
`;
