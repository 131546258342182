import styled from 'styled-components';
import { font, mediaBreakpointUp, hover, vw, color, allBreakpointValue, offset } from '@/style/mixins';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
        width: 100%;
    }
`;

export const FooterContactsPhones = styled.div`
    display: flex;
    flex-direction: column;
    ${font('h5-b')};
    align-items: flex-start;

    a {
        transition: opacity 0.3s ease-in-out;

        ${hover(` opacity: 0.6`)};

        &:not(:last-child) {
            ${allBreakpointValue('margin-bottom', 14, 12, 10, 20, 20)};
        }
    }
`;

export const FooterAddress = styled.address`
    ${font('text1-b')};
    font-style: normal;

    * + & {
        ${offset('l', 'margin-top')};
    }
`;

export const FooterContactsSocials = styled.div`
    display: flex;
    flex-direction: column;
    ${font('text3-l')};

    ${allBreakpointValue('margin-top', 35, 32, 32, 40, 40)};

    a {
        display: flex;
        align-items: center;
        padding: 12px 0;

        ${mediaBreakpointUp('xl')} {
            padding: ${vw(12)} 0;
        }

        ${allBreakpointValue('max-width', 287, 228, 213, 248)};
        transition: opacity 0.3s ease-in-out;
        ${hover(` opacity: 0.6`)};

        border-bottom: thin solid ${color('white', 0.1)};

        &:first-of-type {
            border-top: thin solid ${color('white', 0.1)};
        }

        span {
            &:first-of-type {
                height: 10px;
            }

            &:nth-of-type(2) {
                opacity: 0.8;
                margin-left: ${vw(12)};
            }
        }

        svg {
            display: block;
            fill: currentColor;
            pointer-events: none;
            height: 100%;
        }
    }
`;

export const FooterContactsDocs = styled.div`
    display: flex;
    flex-direction: column;
    ${offset('s', 'margin-top')};
    ${font('text3-l')};
    opacity: 0.8;
    flex-shrink: 0;

    ${mediaBreakpointUp('md')} {
        margin-bottom: 24px;
    }

    ${mediaBreakpointUp('fhd')} {
        margin-bottom: 0;
    }

    a {
        transition: opacity 0.3s ease-in-out;
        ${hover(` opacity: 0.6`)};
    }
`;
