import React from 'react';
import { Container } from './FooterTechSupport.styled';
import { FooterTechSupportI } from '@/interfaces';
import LinkDown from '@/components/ui/LinkDown/LinkDown';
import Link from 'next/link';
import ModalBtn from '@/components/ui/ModalBtn/ModalBtn';

interface FooterTechSupportProps extends FooterTechSupportI {
    className?: string;
}

const FooterTechSupport: React.FC<FooterTechSupportProps> = ({ remoteSupport, support, links, className }) => {
    return (
        <Container className={className}>
            <div>
                {remoteSupport?.title && <p>{remoteSupport?.title}</p>}
                {remoteSupport?.links && (
                    <ul>
                        {remoteSupport.links.map((link, i) => (
                            <li key={i}>
                                <LinkDown {...link} />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div>
                {support?.title && <p>{support?.title}</p>}
                {support?.modalBtns && (
                    <ul>
                        {support.modalBtns.map(({ title, id }, i) => (
                            <li key={i}>
                                <ModalBtn id={id} fromFooter={true}>
                                    {title}
                                </ModalBtn>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div>
                {links && (
                    <ul>
                        {links.map(({ href, text }, i) => (
                            <li key={i}>
                                <Link href={href} passHref prefetch={false}>
                                    <a> {text} </a>
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </Container>
    );
};

export default FooterTechSupport;
