/**
 * @description Оставляет только два первых шага path, получая путь к секции указанной процедуры
 * @example /44-fz/procedures/11395184 преобразует в /44-fz/procedures
 * @example /org/mirea/procedures/11398018 преобразует в /org/mirea
 */

export const getProcedureSectionPath = (path: string) => {
    let pathFinal = path.split('/').slice(0, 3);
    pathFinal = pathFinal[1] === 'procedures' ? pathFinal.slice(0, 2) : pathFinal;
    return pathFinal.join('/');
};
