import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type check = {
    data: boolean;
};

const initialState: check = {
    data: false
};

const rosneftNewsCheckSlice = createSlice({
    name: 'rosneftNewsCheck',
    initialState,
    reducers: {
        setNewsDataExist(state, action: PayloadAction<boolean>) {
            state.data = action.payload;
        }
    }
});

export const { setNewsDataExist } = rosneftNewsCheckSlice.actions;

export default rosneftNewsCheckSlice.reducer;
