import React from 'react';
import Link from 'next/link';
import { LinkI } from '@/interfaces';
import { useRouter } from 'next/router';

const activeLink = (pathname?: string, href?: string, sidebarPath?: string) => {
    const pathnameNoID = pathname?.replace(/\/\[id]/gi, '').trim();
    if (sidebarPath?.includes('?tag=')) {
        sidebarPath = sidebarPath.split('?tag=')[0];
    }

    if (pathname?.match(`${href!}/`)) return true;

    if (sidebarPath === href) return true;

    if (pathnameNoID === href) return true;

    if (pathname!.split('/').length <= 3) {
        return pathname === href;
    } else {
        const path = pathname!.split('/');
        path.pop();
        path.join('/');

        return path.join('/') === href;
    }
};

const SidebarMenuItems = (links: LinkI[], sidebarPath?: string) => {
    const { asPath } = useRouter();
    const pathname = asPath.split('?')[0];

    return (
        <ul>
            {links.map(({ href, text }, i) => (
                <li key={i}>
                    {pathname !== href ? (
                        <Link href={href} passHref prefetch={false} key={i}>
                            <a className={activeLink(pathname, href, sidebarPath) ? 'active' : ''}>{text}</a>
                        </Link>
                    ) : (
                        <p className={activeLink(pathname, href, sidebarPath) ? 'active' : ''}>{text}</p>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default SidebarMenuItems;
