import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IProcedureShort, ReduxStatus } from '@/interfaces';
import { BasicRequestOptions, getProcedures } from '@/lib/api';
import { FilterData } from '@/components/blocks/SearchProcedures/SearchProcedures';

type SearchProceduresState = ReduxStatus & {
    data: IProcedureShort[];
    total: number;
};

const sortSearchProcedures = createAsyncThunk('searchProcedures/sortSearchProcedures', async (params: FilterData) => {
    const response = await getProcedures({ params } as unknown as BasicRequestOptions);
    return response.data;
});

const initialState: SearchProceduresState = {
    status: 'idle',
    error: null,
    data: [],
    total: 0
};

const searchProceduresSlice = createSlice({
    name: 'searchProcedures',
    initialState,
    reducers: {
        resetSearchProcedures(state) {
            return {
                ...initialState,
                total: state.total
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(sortSearchProcedures.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(sortSearchProcedures.fulfilled, (state, action) => {
            if (action.payload.error) {
                state.status = 'failed';
                state.error = action.payload.error.message;
            } else {
                state.status = 'succeeded';
                state.data = action.payload.data;
            }
        });
        builder.addCase(sortSearchProcedures.rejected, (state, { error }) => {
            state.status = 'failed';
            state.error = error.message;
        });
    }
});

export const { resetSearchProcedures } = searchProceduresSlice.actions;
export default searchProceduresSlice.reducer;
