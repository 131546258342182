import { getPicture, getSrc } from '@/helpers';
import { LinkI } from '@/interfaces';
import { Request } from '@/interfaces';
import { getContentApi, getContentApiRoute } from '@/lib/api';
import { getLoggingHeaders } from './api/getLoggingHeaders';

// Общие коды для баннеров
export const enum bannerCodes {
    // Для бокового меню
    rosneftSide = 'rosneft_side',
    fz44Side = '44-fz_side',
    fz223Side = '223-fz_side',
    interraoSide = 'inter_rao_side',
    marketSide = 'market_side',
    saleSide = 'sale_side',
    tendersSide = 'tenders_side',
    clientsSide = 'clients_side',
    servicesSide = 'services_side',
    educationSide = 'education_side',
    aboutCompanySide = 'about-company_side',
    organizatorsSide = 'organizators_side',
    proceduresSide = 'procedures_side',
    bankGuaranteeSide = 'bank-guarantee_side',

    // Для верхнего меню
    sectionsHeader = 'sections_header',
    clientsHeader = 'clients_header',
    servicesHeader = 'services_header',
    educationHeader = 'education_header',
    aboutCompanyHeader = 'about-company_header'
}

/**
 * @description BannerSlider's default delay option value per milliseconds
 */
export const BANNER_SLIDER_DELAY = 3000;

export const getSlugBanners = (data: any[], code: string | undefined) => {
    if (!code) return;

    const slugBanners = data?.filter((item: { slug: bannerCodes }) => item.slug === code)[0];
    const delay = slugBanners?.display_time * 1000 || BANNER_SLIDER_DELAY;
    return (slugBanners?.banners && slugBanners.banners.map((banner: any) => ({ ...banner, delay: delay }))) || slugBanners?.banners;
};

export const convertBannersCodes = (codes: string[], keyName: string) => {
    const obj: {} = codes.reduce((accumulator, value, index) => {
        return { ...accumulator, [`${keyName}[${index}]`]: value };
    }, {});
    return obj;
};

export const getBannersData = async (options: {
    codes: string[];
    locale: string | undefined;
    req?: Request;
    isRequestFromClient?: boolean;
}) => {
    const places = convertBannersCodes(options.codes, 'places');
    let banners = [];

    let bannersPromise;

    if (options.isRequestFromClient) {
        bannersPromise = getContentApiRoute('/banners', { ...places, locale: `${options.locale}` });
    } else {
        bannersPromise = getContentApi('/banners', {
            params: { ...places, locale: `${options.locale}` },
            headers: options.req ? getLoggingHeaders(options.req) : {}
        });
    }

    try {
        const { data } = await bannersPromise;
        if (data?.data) banners = data.data;
    } catch (e) {
        console.error(e);
    }

    return banners;
};

/**
 * @description Возвращает обновлённую структуру баннеров
 */
export const createSimpleBanners = (data: any[], locale?: string, first?: boolean) => {
    if (!data?.length) return undefined;

    const banners = data?.map((item: any) => ({
        title: item.title || '',
        description: item.description || '',
        image: 'image' in item && getSrc(item.image),
        link: {
            href: item.link?.href || '',
            text: (locale && (locale === 'ru' ? 'Посмотреть' : 'View')) || '',
            isForeign: item.link?.isForeign
        },
        textColor: item.is_dark_image ? 'white' : 'black',
        delay: item.delay,
        advertising: item.advertising || null
    }));

    if (first) return banners[0];

    return banners;
};

/**
 * @description Возвращает обновлённую структуру баннеров для компонента AdvertisementBanners
 */
export const updateDataAdvertisementBanners = (data: any[], locale: string) => {
    if (!data?.length) return [];

    const banners = data?.map((item: any) => ({
        title: item.title || '',
        description: item.description || '',
        image: 'image' in item && getSrc(item.image),
        link: {
            href: item.link?.href || '',
            text: '',
            isForeign: item.link?.isForeign
        },
        type: {
            name: item.is_ad ? 'ad' : '',
            text: item.is_ad ? (locale === 'ru' ? 'Реклама' : 'Advertising') : ''
        },
        textColor: item.is_dark_image ? 'white' : 'black',
        advertising: item.advertising || null
    }));

    return banners;
};

/**
 * @description Возвращает обновлённую структуру баннеров для компонента BannerSlider
 */
export const updateDataBannerSlider = (data: any[], locale: string) => {
    if (!data?.length) return [];

    const banners = data?.map((item: any) => ({
        title: item.title || '',
        text: item.description || '',
        background: 'image' in item && getPicture(item.image),
        backgroundLink: item.link,
        links: item.buttons
            ?.map((value: any) => {
                return {
                    href: value.link || '',
                    text: value.text || '',
                    isForeign: value.isForeign
                };
            })
            .filter((link: LinkI) => link.href),
        textColor: item.is_dark_image ? 'white' : 'black',
        tag: item.is_ad && (locale === 'ru' ? 'Реклама' : 'Advertisement'),
        delay: item.delay,
        advertising: item.advertising || null
    }));

    return banners;
};

/**
 * @description Возвращает обновлённую структуру баннеров для компонента Banner
 */
export const updateDataMainBanners = (data: any[]) => {
    if (!data?.length) return [];

    const banners = data?.map((item: any) => ({
        title: item.title || '',
        text: item.description || '',
        image: 'image' in item && getSrc(item.image),
        link: {
            href: (item.buttons && item.buttons[0]?.link) || '',
            text: (item.buttons && item.buttons[0]?.text) || '',
            isForeign: item.buttons && item.buttons[0]?.isForeign
        },
        textColor: item.is_dark_image ? 'white' : 'black',
        advertising: item.advertising || null
    }));

    return banners;
};

// Баннеры для верхнего меню
export const getHeaderBanners = async (locale: string) => {
    const banners = await getBannersData({
        codes: [
            bannerCodes.sectionsHeader,
            bannerCodes.clientsHeader,
            bannerCodes.servicesHeader,
            bannerCodes.educationHeader,
            bannerCodes.aboutCompanyHeader
        ],
        locale,
        isRequestFromClient: true
    });
    const updateBanners = banners?.map((item: { slug: bannerCodes; banners: any }) => {
        if (item.slug === bannerCodes.sectionsHeader) return { id: 'tradeSections', card: createSimpleBanners(item.banners, locale, true) };
        if (item.slug === bannerCodes.clientsHeader) return { id: 'clients', card: createSimpleBanners(item.banners, locale, true) };
        if (item.slug === bannerCodes.servicesHeader) return { id: 'services', card: createSimpleBanners(item.banners, locale, true) };
        if (item.slug === bannerCodes.educationHeader) return { id: 'education', card: createSimpleBanners(item.banners, locale, true) };
        if (item.slug === bannerCodes.aboutCompanyHeader)
            return { id: 'aboutCompany', card: createSimpleBanners(item.banners, locale, true) };
    });

    return updateBanners;
};

// Баннеры для бокового меню
export const getSideBanners = async (locale: string) => {
    const banners = await getBannersData({
        codes: [
            bannerCodes.rosneftSide,
            bannerCodes.fz44Side,
            bannerCodes.fz223Side,
            bannerCodes.interraoSide,
            bannerCodes.marketSide,
            bannerCodes.saleSide,
            bannerCodes.tendersSide,
            bannerCodes.clientsSide,
            bannerCodes.servicesSide,
            bannerCodes.educationSide,
            bannerCodes.aboutCompanySide,
            bannerCodes.organizatorsSide,
            bannerCodes.proceduresSide,
            bannerCodes.bankGuaranteeSide
        ],
        locale,
        isRequestFromClient: true
    });

    const updateBanners = banners?.map((item: { slug: bannerCodes; display_time?: number; banners: any }) => {
        return { slug: item.slug, display_time: item.display_time, banners: createSimpleBanners(item.banners) };
    });

    return updateBanners;
};
