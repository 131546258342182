import React, { ForwardedRef, useEffect, useRef } from 'react';
import {
    Container,
    Card1Background,
    Card1Tag,
    Card1More,
    Card1Tags,
    Card1Play,
    Card1Mask,
    Card1TextBottom,
    Card1Icons,
    Card1Icon,
    Card1FileName,
    SvgMask,
    Title2,
    Title,
    Partition,
    Card1Bottom
} from './Card1.styled';
import { Card1ContainerProps, ITag, LinkI, ModalBtnI } from '@/interfaces';
import Link from 'next/link';
import { webkitLineClamp } from 'webkit-line-clamp';
import Arrow from '@/icons/arrow.svg';
import { Colors } from '@/style/colors';
import { SvgElements } from '@/helpers/icons';
import dynamic from 'next/dynamic';
import ModalBtn from '@/components/ui/ModalBtn/ModalBtn';
import { PictureType } from '@/components/common/Picture/Picture';
import useTranslation from '@/hooks/useTranslation';

const Buttons = dynamic(() => import('@/components/blocks/Buttons/Buttons'));
const Picture = dynamic(() => import('@/components/common/Picture/Picture'));

export interface Card1Props extends Card1ContainerProps {
    render?: JSX.Element;
    noRender?: boolean;
    className?: string;
    title: string;
    description?: string;
    image?: string;
    picture?: PictureType;
    type?: {
        name: string;
        text?: string;
    };
    date?: string;
    link: LinkI;
    withoutPreview?: boolean;
    target?: React.HTMLAttributeAnchorTarget;
    tags?: ITag[];
    linkMore?: boolean;
    titleBg?: boolean;
    linkBg?: boolean;
    clamp?: number | null;
    iconNames?: string[];
    dateColor?: Colors;
    file?: string;
    isHover?: boolean;
    textBottom?: string;
    renderSvg?: React.ReactNode;
    modal?: ModalBtnI[];
    metrika?: React.MouseEventHandler;
    clickOnWrapper?: React.MouseEventHandler;
    arrow?: boolean;
    noRenderSvg?: boolean;
    flippedTags?: boolean;
    tagsNearDate?: boolean;
    tagBgColor?: Colors;
}

const Card1 = React.forwardRef(
    (
        {
            className,
            title,
            description,
            picture,
            image,
            backgroundImage,
            type,
            date,
            link,
            withoutPreview,
            target,
            tags,
            linkMore = true,
            titleBg = false,
            linkBg = true,
            bgColor,
            textColor,
            dateColor,
            textHover,
            bgHover,
            maskHover,
            fullWidthTitle = false,
            reverseFonts = false,
            clamp = null,
            iconNames,
            file,
            isHover = true,
            textBottom,
            renderSvg,
            noRenderSvg,
            modal,
            fullModalCard,
            arrow,
            partitions,
            flippedTags,
            metrika,
            clickOnWrapper,
            tagsNearDate = false,
            tagBgColor
        }: Card1Props,
        ref: ForwardedRef<HTMLAnchorElement>
    ) => {
        const refTitle = useRef<HTMLDivElement | null>(null);
        const { t } = useTranslation('common');

        useEffect(() => {
            if (refTitle.current && clamp) {
                webkitLineClamp(refTitle.current, clamp);
            }
        }, [refTitle]);

        const inner = (
            <Container
                ref={ref}
                className={className}
                isHover={isHover}
                type={type?.name}
                bgColor={bgColor}
                imageBackground={image}
                backgroundTitle={titleBg}
                textColor={textColor}
                textHover={textHover}
                bgHover={bgHover}
                fullWidthTitle={fullWidthTitle}
                reverseFonts={reverseFonts}
                target={link?.isForeign ? '_blank' : '_self'}
                maskHover={maskHover}
                backgroundImage={backgroundImage}
                onClick={clickOnWrapper}
                partitions={partitions}
                flippedTags={flippedTags}
            >
                {image && <Card1Background bg={bgColor} src={image} alt={title} layout="fill" objectFit="cover" />}

                {picture && <Picture {...picture} />}

                {partitions && <Partition>{t('category', { returnObjects: true })}</Partition>}

                {renderSvg && <SvgMask>{noRenderSvg ? '' : renderSvg}</SvgMask>}

                {title && !arrow && (
                    <Title
                        ref={refTitle}
                        VideoCard={type?.name === 'video' && !withoutPreview}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                )}

                {description && <div className="card1__description" dangerouslySetInnerHTML={{ __html: description }} />}

                {type?.name === 'ad' && <Card1Tag backgroundColor={tagBgColor}>{type.text}</Card1Tag>}

                {type?.name === 'video' && <Card1Play />}

                {linkMore && link && (
                    <Card1More background={linkBg} onClick={metrika}>
                        {link.text}
                        <Arrow />
                    </Card1More>
                )}

                {tags && !tagsNearDate && (
                    <Card1Tags>
                        {tags.map((tag, index) => (
                            <Card1Tag key={index} backgroundColor={tagBgColor}>
                                {tag.title}
                            </Card1Tag>
                        ))}
                    </Card1Tags>
                )}

                {date && !tagsNearDate && <Card1TextBottom dangerouslySetInnerHTML={{ __html: date }} dateColor={dateColor} />}

                {tagsNearDate && (date || tags) && (
                    <Card1Bottom>
                        {date && <Card1TextBottom dangerouslySetInnerHTML={{ __html: date }} dateColor={dateColor} />}

                        {tags && (
                            <Card1Tags>
                                {tags.map((tag, index) => (
                                    <Card1Tag key={index} backgroundColor={tagBgColor}>
                                        {tag.title}
                                    </Card1Tag>
                                ))}
                            </Card1Tags>
                        )}
                    </Card1Bottom>
                )}

                {textBottom && (
                    <Card1TextBottom withArrow>
                        <span dangerouslySetInnerHTML={{ __html: textBottom }} />
                        <Arrow />
                    </Card1TextBottom>
                )}

                {titleBg && <Card1Mask colorBg={textColor} />}

                {iconNames && (
                    <Card1Icons>
                        {iconNames.map((name, index) => (
                            <Card1Icon key={index}>{SvgElements[name]}</Card1Icon>
                        ))}
                    </Card1Icons>
                )}
                {file && <Card1FileName>{file}</Card1FileName>}

                {title && arrow && (
                    <Title2>
                        <h3 ref={refTitle} dangerouslySetInnerHTML={{ __html: title }} />
                        {arrow ? <Arrow /> : ''}
                    </Title2>
                )}
            </Container>
        );
        return modal ? (
            <Container
                className={className}
                isHover={isHover}
                type={type?.name}
                bgColor={bgColor}
                imageBackground={image}
                backgroundTitle={titleBg}
                textColor={textColor}
                textHover={textHover}
                bgHover={bgHover}
                fullWidthTitle={fullWidthTitle}
                reverseFonts={reverseFonts}
                target={target}
                maskHover={maskHover}
                backgroundImage={backgroundImage}
                fullModalCard={fullModalCard}
                as={fullModalCard ? 'article' : 'a'}
                onClick={clickOnWrapper}
            >
                {fullModalCard ? (
                    modal?.map(({ id }, i) => (
                        <ModalBtn key={i} id={id} as={'div'}>
                            {title && <h3 ref={refTitle} dangerouslySetInnerHTML={{ __html: title }} />}
                            {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
                            <Card1More textColor={textColor} background={linkBg}>
                                {<span>{modal?.map(({ title }) => title)}</span>}
                                <Arrow />
                            </Card1More>
                        </ModalBtn>
                    ))
                ) : (
                    <>
                        {title && <h3 ref={refTitle} dangerouslySetInnerHTML={{ __html: title }} />}
                        {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
                        <Card1More textColor={textColor} background={linkBg}>
                            <Buttons modals={modal} />
                            <Arrow />
                        </Card1More>
                    </>
                )}
                {image && <Card1Background src={image} alt={title} layout="fill" objectFit="cover" />}
            </Container>
        ) : link?.href ? (
            <Link href={link.href} passHref prefetch={false}>
                {inner}
            </Link>
        ) : (
            <>{inner}</>
        );
    }
);

Card1.displayName = 'Card1';

export default Card1;
