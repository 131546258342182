import { useState, useEffect } from 'react';

/**
 * @description Возвращает объект содержащий свойство titles, которое содержит объек с title страницы в двух форматах:
 * title: 'Обучение по тендерам и закупкам от ЭТП ТЭК-Торг'
 * pageTitle: 'ОБУЧЕНИЕ-ПО-ТЕНДЕРАМ-И-ЗАКУПКАМ-ОТ-ЭТП-ТЭК-ТОРГ'
 */
export const useServicePage = () => {
    const [titles, setTitles] = useState({
        title: '',
        pageTitle: ''
    });

    useEffect(() => {
        const serviceTitle = document?.title.replace(/\| ТЭК-Торг/gi, '').trim();
        const servicepageTitle = serviceTitle?.toUpperCase().replace(/ /gi, '-');

        setTitles((prev) => ({ ...prev, title: serviceTitle, pageTitle: servicepageTitle }));
    }, []);
    return { titles };
};
