import React from 'react';
import { Colors } from '@/style/colors';
import { Container, Text, Spinner } from './Button.styled';
import { BaseUI } from '@/interfaces';

export interface ButtonProps extends BaseUI {
    haveBg?: boolean;
    small?: boolean;
    bgColor?: Colors;
    textColor?: Colors;
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: (e: React.SyntheticEvent) => void;
}

const Button: React.FC<ButtonProps> = ({
    className,
    haveBg = false,
    small = false,
    bgColor = 'white',
    textColor = 'black',
    isLoading = false,
    children,
    ...props
}) => {
    return (
        <Container className={className} haveBg={haveBg} small={small} bgColor={bgColor} textColor={textColor} {...props}>
            <Text isLoading={isLoading}>{children}</Text>
            {isLoading && <Spinner loadingAnimation />}
        </Container>
    );
};

export default Button;
