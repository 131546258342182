import Stylis from '@emotion/stylis';
import { getRuls } from './getRuls';

export interface WebvisorStyle extends HTMLStyleElement {
    cssRulesNum: number;
}

export const setDOMStyles = (webvisorStyle: WebvisorStyle, styleContainer: HTMLStyleElement, stylis: Stylis) => {
    if (webvisorStyle.cssRulesNum !== styleContainer.sheet!.cssRules.length) {
        new Promise((resolve: (value: string) => void) => {
            const stylesString = Object.values(styleContainer.sheet!.cssRules)
                .map((obj) => getRuls(stylis, obj))
                .join('');

            webvisorStyle.cssRulesNum = styleContainer.sheet!.cssRules.length;

            resolve(stylesString);
        })
            .then((result) => (webvisorStyle.innerHTML = result))
            .catch((err) => console.error('Ошибка добавления стилей: ' + err));
    }
};
