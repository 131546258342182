import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type importantMessageState = {
    first: boolean;
    message: {
        title: string;
        slug: string;
        publication_date_from: string;
    } | null;
    localeMessage: string;
    hide: boolean;
};

const initialState: importantMessageState = {
    first: false,
    message: null,
    localeMessage: 'ru',
    hide: false
};

const importantMessageSlice = createSlice({
    name: 'importantMessage',
    initialState,
    reducers: {
        setFirst(state, action: PayloadAction<boolean>) {
            state.first = action.payload;
        },
        setMessage(state, action) {
            state.message = action.payload;
        },
        setLocale(state, action) {
            state.localeMessage = action.payload;
        },
        setHide(state, action) {
            state.hide = action.payload;
        }
    }
});

export const { setFirst, setMessage, setLocale, setHide } = importantMessageSlice.actions;

export default importantMessageSlice.reducer;
