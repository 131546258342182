import styled from 'styled-components';
import { vw, color, font, mediaBreakpointUp, offset, allBreakpointValue, mediaBreakpointDown, hover } from '@/style/mixins';
import Spoiler from '@/components/blocks/Spoiler/Spoiler';
import Card1 from '@/components/blocks/Card1/Card1';
import { Card1More } from '@/components/blocks/Card1/Card1.styled';
import { SpoilerHead, SpoilerHeadBtn } from '@/components/blocks/Spoiler/Spoiler.styled';
import { SimpleSliderSwiper, Container as SimpleSlider } from '@/components/common/SimpleSlider/SimpleSlider.styled';

export const Container = styled.div`
    position: sticky;
    top: 4.6vw;
    width: 100%;

    ${mediaBreakpointUp('xl')} {
        display: block;
        flex-shrink: 0;
        width: ${vw(295, 'xl')};
        height: 99.99%;
    }

    ${mediaBreakpointUp('xxl')} {
        width: ${vw(332, 'xxl')};
    }

    ${mediaBreakpointUp('fhd')} {
        width: ${vw(403)};
    }

    ${SimpleSlider} {
        padding: 0;
    }

    ${SimpleSliderSwiper} {
        .swiper-slide {
            width: 100%;
            height: auto;
        }
    }

    ${mediaBreakpointDown('sm')} {
        ${SpoilerHead} {
            flex-direction: column;
        }

        ${SpoilerHeadBtn} {
            position: static;
        }
    }
`;

export const SidebarScroll = styled.div`
    overflow-y: auto;
    max-height: 92vh;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color 0.3s;

    & > :last-child {
        ${offset('xxxl', 'padding-bottom')};
    }

    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: background-color 0.3s ease-in-out;
    }

    ${hover(`
        scrollbar-color: ${color('black', 0.15)} ${color('black', 0.05)};

        ::-webkit-scrollbar-track {
            background-color: ${color('black', 0.05)};
        }

        ::-webkit-scrollbar-thumb {
            background-color: ${color('black', 0.15)};
        }
    `)};
`;

export const SidebarHead = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    ${allBreakpointValue('padding-top', 60, 60, 54, 16, 16)};
    ${allBreakpointValue('padding-right', 30, 33, 29, 40, 20)};
    ${allBreakpointValue('padding-bottom', 27, 30, 26, 20, 20)};
    ${allBreakpointValue('padding-left', 80, 60, 54, 40, 20)};

    ${mediaBreakpointDown('xl')} {
        height: 68px;
    }

    ${mediaBreakpointDown('sm')} {
        margin-bottom: 20px;

        &::before {
            bottom: -40px;
        }
    }
`;

export const SidebarLogo = styled.a<{ withText: boolean; pointerNone?: boolean }>`
    display: flex;
    align-items: center;

    ${mediaBreakpointDown('xl')} {
        height: 100%;
    }

    svg {
        display: block;
        width: auto;
        max-width: 100%;
        min-height: 32px;

        ._is-ie & {
            width: 100px;
            min-height: 32px;
            ${allBreakpointValue('width', 186, 139, 126)};
        }
    }

    pointer-events: ${({ pointerNone }) => pointerNone && 'none'};

    ${({ withText }) =>
        withText
            ? `
                svg {
                    width: 18px;
                    height: 18px;

                    ${mediaBreakpointUp('fhd')} {
                        width: ${vw(24)};
                        height: ${vw(24)};
                    }
                }
            `
            : `
                svg {
                    ${allBreakpointValue('height', 59, 44, 40)};

                    ${mediaBreakpointDown('xl')} {
                        height: inherit;
                    }
                }
            `};
`;

export const SidebarLogoText = styled.p<{ big: boolean }>`
    margin-left: 12px;
    ${({ big }) => (big ? font('h5-b') : font('text1-b'))};

    ${mediaBreakpointUp('fhd')} {
        margin-left: 16px;
    }
`;

export const SidebarMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${allBreakpointValue('padding-right', 30, 33, 29, 40, 20)};
    ${allBreakpointValue('padding-bottom', 24, 30, 26, 20, 20)};
    ${allBreakpointValue('padding-left', 80, 60, 54, 40, 20)};
`;

export const SidebarMenuSpoiler = styled(Spoiler)``;

export const SidebarMenuItem = styled.div<{ active: boolean }>`
    position: relative;
    width: 100%;

    p,
    a {
        transition: color 0.3s ease-in-out;
    }

    p {
        ${font('text1-b')};

        & + ul {
            margin-top: 12px;
        }

        &.active {
            color: ${({ theme }) => color(theme.hover)};
        }
    }

    a {
        @media (hover: hover) {
            &:hover {
                color: ${({ theme }) => color(theme.hover)};
            }
        }

        ._is-ie & {
            &:hover {
                color: ${({ theme }) => color(theme.hover)};
            }
        }

        &.active {
            color: ${({ theme }) => color(theme.hover)};
        }
    }

    ul {
        padding-left: 20px;
        ${allBreakpointValue('margin-top', 16, 12, 10, 12, 12)};
        ${allBreakpointValue('margin-bottom', 14, 10, 8, 10, 10)};
    }

    li {
        list-style: none;
        ${font('text2-b')};

        &:not(:last-child) {
            ${allBreakpointValue('margin-bottom', 10, 6, 6, 12, 12)};
        }

        & > p {
            ${font('text2-b')};
        }
    }

    &:not(:last-child) {
        ${allBreakpointValue('margin-bottom', 12, 12, 14, 16, 16)};
    }

    ${SpoilerHead} {
        p {
            color: ${({ active, theme }) => (active ? color(theme.hover) : color('black'))};

            @media (hover: hover) {
                &:hover {
                    color: ${({ theme }) => color(theme.hover)};
                }
            }

            ._is-ie & {
                &:hover {
                    color: ${({ theme }) => color(theme.hover)};
                }
            }
        }

        &._open {
            p {
                color: ${({ theme }) => color(theme.hover)};
            }
        }
    }
`;

export const SidebarBottom = styled.div`
    position: relative;
    ${allBreakpointValue('padding-right', 30, 33, 29, 40, 20)};
    ${allBreakpointValue('padding-bottom', 30, 22, 20, 40, 40)};
    ${allBreakpointValue('padding-left', 80, 60, 54, 40, 20)};
`;

export const SidebarBanner = styled(Card1)`
    align-items: flex-start;
    width: 240px;
    ${allBreakpointValue('height', 143, 118, 104, 118, 118)};
    ${allBreakpointValue('padding', 12, 10, 8, 10, 10)};

    ${mediaBreakpointUp('xl')} {
        width: 100%;
    }

    h3 {
        margin: 0;
        ${font('text2-b')};
    }

    ${Card1More} {
        position: relative;
        height: auto;
        margin-left: auto;
        margin-top: auto;
        padding-right: 0;
    }
`;
