import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface footerState {
    isVisible: boolean;
}

const initialState: footerState = {
    isVisible: false
};

const footerSlice = createSlice({
    name: 'footerSlice',
    initialState,
    reducers: {
        setIsFooterVisible(state, { payload }: PayloadAction<boolean>) {
            state.isVisible = payload;
        }
    }
});

export const { setIsFooterVisible } = footerSlice.actions;

export default footerSlice.reducer;
