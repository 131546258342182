import { SwiperSlide } from 'swiper/react';
import { SidebarBanner } from './Sidebar.styled';
import { clearFromNBSP, sendMetrik } from '@/helpers';
import dynamic from 'next/dynamic';
import { BANNER_SLIDER_DELAY } from '@/helpers/bannerHelpers';
import { LinkI } from '@/interfaces';
import { Colors } from '@/style/colors';
const SimpleSlider = dynamic(() => import('@/components/common/SimpleSlider/SimpleSlider'));

type Banner = {
    delay?: number;
    description?: string;
    image?: string;
    link: LinkI;
    textColor?: Colors;
    title: string;
};

const BannerSlider: React.FC<{ banner: Banner[] | Banner }> = (banner) => {
    return (
        <>
            {Array.isArray(banner) && banner?.length > 1 ? (
                <SimpleSlider
                    showNavigation={false}
                    swiperOptions={{
                        allowTouchMove: false,
                        grabCursor: false,
                        keyboard: false,
                        autoplay: {
                            delay: banner[0].delay || BANNER_SLIDER_DELAY,
                            disableOnInteraction: false
                        },
                        loop: true,
                        spaceBetween: 8
                    }}
                >
                    {banner.map((item, index) => (
                        <SwiperSlide key={index}>
                            <SidebarBanner
                                {...item}
                                linkBg={false}
                                clickOnWrapper={() =>
                                    sendMetrik('banners-clicks', 'banners', { 'banner-id-click': clearFromNBSP(item.title) }, 'clicks')
                                }
                            />
                        </SwiperSlide>
                    ))}
                </SimpleSlider>
            ) : (
                <SidebarBanner
                    {...(Array.isArray(banner) && !!banner.length ? banner[0] : banner)}
                    linkBg={false}
                    clickOnWrapper={() =>
                        sendMetrik(
                            'banners-clicks',
                            'banners',
                            {
                                'banner-id-click': clearFromNBSP(
                                    Array.isArray(banner) && !!banner.length ? banner[0].title : (banner as unknown as Banner).title
                                )
                            },
                            'clicks'
                        )
                    }
                />
            )}
        </>
    );
};

export default BannerSlider;
