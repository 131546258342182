import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import debounce from 'lodash.debounce';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * @description Определение высоты плашки важного уведомления в шапке сайта для последующей установки отступа основного контента.
 */

const useImportantMessageHeight = (headerRef: MutableRefObject<HTMLDivElement | null> | undefined) => {
    const { locale } = useRouter();
    const [layOutOffset, setLayOutOffset] = useState<number>(0);
    const resizeCounter = useRef(0);

    const { message, hide } = useSelector(({ importantMessage }: RootState) => importantMessage);

    const getImportantMessageHeight = () => {
        if (typeof window !== 'undefined' && headerRef) {
            return headerRef.current?.querySelector('._important')?.clientHeight;
        }
    };

    useEffect(() => {
        const resizeHandler = () => resizeCounter.current++;

        const debouncedResizeHandler = debounce(resizeHandler, 100);
        const resizeObserver = new ResizeObserver(debouncedResizeHandler);
        resizeObserver?.observe(document.documentElement);
        resizeHandler();

        return () => resizeObserver?.disconnect();
    }, []);

    useEffect(() => {
        if (message && !Cookies.get(`importantMessage_${locale}`)) {
            const importantMessageHeight = getImportantMessageHeight();
            importantMessageHeight && setLayOutOffset(importantMessageHeight - 1);
        } else if (hide || !message) {
            setLayOutOffset(0);
        }
    }, [hide, message, resizeCounter.current]);

    return layOutOffset;
};

export default useImportantMessageHeight;
