import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { preloaderStateTypes } from './types';

const initialState: preloaderStateTypes = {
    countMediaLoaded: 0,
    totalMedia: 0,
    animationDone: false,
    done: false,
    exitActive: false,
    loading: false
};

const preloaderSlice = createSlice({
    name: 'preloader',
    initialState,
    reducers: {
        setAnimationDone(state, action: PayloadAction<boolean>) {
            state.animationDone = action.payload;
        },
        setDone(state, action: PayloadAction<boolean>) {
            state.done = action.payload;
        },
        setExitState(state, action: PayloadAction<boolean>) {
            state.exitActive = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        }
    }
});

export const { setAnimationDone, setDone, setExitState, setLoading } = preloaderSlice.actions;

export default preloaderSlice.reducer;
