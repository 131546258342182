import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const _223FZ: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/223-fz': SAMPLES.CONTACT_MANAGER_FORM_SAMPLES,
    '/223-fz/help-customers': SAMPLES.CLIENT_CENTER_FORMS_SAMPLES, // работает
    '/223-fz/help-suppliers': SAMPLES.CLIENT_CENTER_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers': SAMPLES.SERVICE_BLOCKS_INTERACTION_TAGS, // Работает
    '/223-fz/service-suppliers/e-signature': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/fast-accreditation': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/eruz': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/134': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/icwe': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/accompany-tenders': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/edo': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/factoring': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/credit-on-contract': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/bg': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-suppliers/credit': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/223-fz/service-suppliers/credit-on-procurement': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-customers-223-fz': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-customers-223-fz/nmc': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-customers-223-fz/digital_helper': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-customers-223-fz/outsourcing': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-customers-223-fz/audit': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-customers': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-customers/nmc': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-customers/digital_helper': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/223-fz/service-customers/outsourcing': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES // работает
};
