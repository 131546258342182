import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import { useRouter } from 'next/router';
import Link from 'next/link';

import {
    Container,
    SidebarHead,
    SidebarLogo,
    SidebarLogoText,
    SidebarMenu,
    SidebarMenuItem,
    SidebarMenuSpoiler,
    SidebarBottom,
    SidebarScroll
} from './Sidebar.styled';
import { SvgElements } from '@/helpers/icons';
import useIsMatches from '@/hooks/useIsMatches';
import SidebarMenuItems from '@/components/common/Sidebar/SidebarMenuItems';
import PotentiallyLink from '@/components/common/PotentiallyLink/PotentiallyLink';
import { LinkI, SidebarI } from '@/interfaces';
import { getSlugBanners } from '@/helpers/bannerHelpers';
import BannerSlider from './SidebarBannerSlider';
import checkSidebarMenuItem from '@/helpers/checkSidebarMenuItem';
import useBannerMetrics from '@/hooks/useBannerMetrics';

export type SidebarProps = SidebarI;

export const activeLink = (pathname?: string, href?: string) => {
    if (pathname?.startsWith('/rosneft/announsments/') || pathname?.startsWith('/rosneft/news/category/')) {
        if (href === '/rosneft/news') return true;
    }

    const pathItems = pathname?.split('/') ?? [];

    if (pathItems.length <= 3) {
        return pathname === href;
    } else {
        if (pathname === href) {
            return true;
        }

        if (Array.isArray(pathItems)) {
            if (pathItems.length) {
                pathItems.pop();
                return pathItems.join('/') === href;
            }
        }

        return false;
    }
};

export const hasActiveItems = (pathname: string, subLinks?: LinkI[], sidebarPath?: string) => {
    if (!subLinks?.length) return false;

    if (sidebarPath?.includes('?tag=')) {
        sidebarPath = sidebarPath.split('?tag=')[0];
    }

    if (pathname.startsWith('/education/events/') || pathname.startsWith('/education/courses/')) {
        if (subLinks.some(({ href }) => href.includes('/education/courses'))) return true;
    }

    if (subLinks.some(({ href }) => href === sidebarPath)) return true;

    if (subLinks.some(({ href }) => pathname!.includes(href))) return true;

    return subLinks.some(({ href }) => href === pathname!.replace(/\/\[id]/gi, '').trim());
};

const xlSize = 1024;

const Sidebar: React.FC<SidebarProps> = ({ header, menu, sidebarCode, sidebarPath }) => {
    const isMobile = useIsMatches('xl');
    const router = useRouter();
    const { locale, asPath } = router;
    const pathname = asPath.split('?')[0];
    const { ref, inView } = useInView({ triggerOnce: true });
    const check = useSelector((state: RootState) => state.rosneftNewsCheck.data);
    const actualMenu = checkSidebarMenuItem({ menu, check, pathname, locale });
    const sideBanners = useSelector((state: RootState) => getSlugBanners(state.banners.sideBanners, sidebarCode || undefined));

    useBannerMetrics(inView, sideBanners);

    return (
        <Container className="sidebar">
            {!isMobile && globalThis.document?.documentElement.clientWidth >= xlSize && (
                <SidebarScroll>
                    <SidebarHead>
                        <Link href={header.href} passHref prefetch={false}>
                            <SidebarLogo withText={!!header.logoText} pointerNone={pathname === header.href}>
                                {SvgElements[header.logo]}
                                {header.logoText && (
                                    <SidebarLogoText big={!!header.textIsBig} dangerouslySetInnerHTML={{ __html: header.logoText }} />
                                )}
                            </SidebarLogo>
                        </Link>
                    </SidebarHead>
                    {actualMenu && (
                        <SidebarMenu>
                            {actualMenu.map((item, index) => (
                                <SidebarMenuItem key={index} active={activeLink(pathname, item.href)}>
                                    {item.subLinks && !!item.subLinks.length ? (
                                        <SidebarMenuSpoiler
                                            activeIndex={hasActiveItems(asPath, item.subLinks, sidebarPath)}
                                            key={index}
                                            header={<p>{item.text}</p>}
                                        >
                                            {SidebarMenuItems(item.subLinks, sidebarPath)}
                                        </SidebarMenuSpoiler>
                                    ) : (
                                        <p className={activeLink(pathname, item.href) ? 'active' : ''}> {PotentiallyLink(item)} </p>
                                    )}
                                </SidebarMenuItem>
                            ))}
                        </SidebarMenu>
                    )}
                    <SidebarBottom ref={ref}>{sideBanners && BannerSlider(sideBanners)}</SidebarBottom>
                </SidebarScroll>
            )}
        </Container>
    );
};

export default Sidebar;
