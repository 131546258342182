import { useMemo } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { CombinedState, Store, PreloadedState } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer, { RootState } from './rootReducer';
import { useDispatch } from 'react-redux';

let store: Store<CombinedState<RootState>> | undefined;

const initStore = (preloadedState: PreloadedState<RootState> = {}) =>
    configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: [thunkMiddleware],
        devTools: true
    });

const initializeStore = (preloadedState: RootState) => {
    let _store = store ?? initStore(preloadedState);

    if (preloadedState && store) {
        _store = initStore({
            ...store.getState(),
            ...preloadedState
        });

        store = undefined;
    }

    if (typeof window === 'undefined') return _store;
    if (!store) store = _store;

    return _store;
};

export const useStore = (initialState: RootState) => {
    return useMemo(() => initializeStore(initialState), [initialState]);
};

//@ts-ignore
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
