import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';
import { offset, font, mediaBreakpointUp, color, allBreakpointValue, sideOffsets, mediaBreakpointDown } from '@/style/mixins';
import RectLink from '@/components/ui/RectLink/RectLink';
import { Colors } from '@/style/colors';
import { BaseOffsetsUI } from '@/interfaces';

export const Container = styled.div<BaseOffsetsUI & { bg?: Colors }>`
    position: relative;
    ${sideOffsets()}
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'padding-top') : offset('xxxl', 'padding-top'))};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')};
    ${({ bg }) => bg && `background-color: ${color(bg)}`};
`;

export const SimpleSliderHeader = styled.div<{ border: boolean; hasButtons?: boolean; hasNav?: boolean }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${offset('xl', 'margin-bottom')};
    ${({ border }) => border && offset('l', 'padding-bottom')};

    ${mediaBreakpointUp('md')} {
        align-items: center;
        flex-direction: row;
    }

    ${mediaBreakpointUp('xl')} {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;

        &._prepare {
            opacity: 0;
            transform: translate3d(0, 30px, 0);
            transition-duration: 0s;
        }
    }

    ${({ border }) =>
        border &&
        css`
            &::before {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 100vw;
                height: 1px;
                background-color: ${color('black', 0.1)};
                transform: translateX(-50%);
            }
        `}

    h2 {
        margin-bottom: ${({ hasButtons }) => (hasButtons ? '24px' : 0)};
        ${font('h5-b')};

        ${mediaBreakpointUp('md')} {
            margin-bottom: 0;
            ${({ hasButtons }) => hasButtons && offset('xl', 'margin-right')};
        }

        ${mediaBreakpointUp('xl')} {
            ${({ hasNav }) => hasNav && offset('xl', 'margin-right')};
        }

        ._is-ie & {
            margin-right: auto;
        }
    }

    & > div {
        display: flex;
        align-items: center;
        align-self: flex-start;
        flex-shrink: 0;
    }
`;

export const SimpleSliderLink = styled(RectLink)`
    &:first-of-type {
        ${allBreakpointValue('margin-left', 45, 45, 40)};
    }

    &:not(:first-child) {
        ${offset('xxs', 'margin-left')};
    }
`;

export const SimpleSliderSwiper = styled(Swiper)`
    .swiper-slide {
        height: auto;

        ${mediaBreakpointDown('xl')} {
            /* iOS & Android fix -start */

            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0);

            /* iOS & Android fix -end */
        }
    }
`;

export const SimpleSliderWrapper = styled.div`
    ${mediaBreakpointUp('xl')} {
        ${SimpleSliderSwiper} {
            transition: transform 0.7s 0.45s ease-in-out, opacity 0.7s 0.45s ease-in-out;

            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        &._prepare {
            ${SimpleSliderSwiper} {
                opacity: 0;
                transform: translate3d(0, 30px, 0);
                transition-duration: 0s;
            }
        }
    }

    ${mediaBreakpointDown('xl')} {
        .swiper-wrapper {
            /* iOS & Android fix -start */

            transform-style: preserve-3d;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0);

            /* iOS & Android fix -end */
        }
    }
`;
