import React from 'react';
import { Container } from './LinkDown.styled';
import Down from '@/icons/down.svg';
import { DocsLinkI } from '@/interfaces';

interface LinkDownProps extends DocsLinkI {}

const LinkDown: React.FC<LinkDownProps> = ({ href, text, target, download }) => {
    return (
        <Container href={href} download={download} target={target}>
            {text} <Down />
        </Container>
    );
};

export default LinkDown;
