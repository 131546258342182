const variables = {
    col: 100 / 24,
    offset: {
        mobile: 20,
        tablet: 40,
        desktop1280: 53,
        desktop1440: 60,
        desktop1920: 80
    },
    videoRatio: (9 / 16) * 100,
    fonts: {
        default: 'Arial',
        NunitoSansLight: 'NunitoSans-Light, Arial, sans-serif',
        NunitoSansSemiBold: 'NunitoSans-SemiBold, Arial, sans-serif',
        SuisseIntlLight: 'NunitoSans-Light, Arial, sans-serif',
        SuisseIntlBook: 'NunitoSans-SemiBold, Arial, sans-serif'
    },
    offsets: {
        //для обнуления отступа
        none: {
            desktop1920: 0,
            desktop1440: 0,
            desktop1280: 0,
            tablet768: 0,
            mobile375: 0
        },
        // 100
        xxxl: {
            desktop1920: 90,
            desktop1440: 75,
            desktop1280: 66,
            tablet768: 48,
            mobile375: 48
        },
        // 80
        xxl: {
            desktop1920: 80,
            desktop1440: 60,
            desktop1280: 54,
            tablet768: 40,
            mobile375: 40
        },
        // 60
        xl: {
            desktop1920: 45,
            desktop1440: 45,
            desktop1280: 40,
            tablet768: 32,
            mobile375: 32
        },
        // 40
        l: {
            desktop1920: 35,
            desktop1440: 30,
            desktop1280: 26,
            tablet768: 24,
            mobile375: 24
        },
        // 30
        m: {
            desktop1920: 27,
            desktop1440: 22,
            desktop1280: 20,
            tablet768: 20,
            mobile375: 20
        },
        s: {
            desktop1920: 22,
            desktop1440: 18,
            desktop1280: 16,
            tablet768: 16,
            mobile375: 16
        },
        xs: {
            desktop1920: 20,
            desktop1440: 16,
            desktop1280: 14,
            tablet768: 14,
            mobile375: 14
        },
        xxs: {
            desktop1920: 14,
            desktop1440: 12,
            desktop1280: 10,
            tablet768: 8,
            mobile375: 8
        },
        xxxs: {
            desktop1920: 12,
            desktop1440: 8,
            desktop1280: 6,
            tablet768: 6,
            mobile375: 6
        },
        xxxxs: {
            desktop1920: 10,
            desktop1440: 8,
            desktop1280: 6,
            tablet768: 8,
            mobile375: 8
        }
    }
};

export type FontFamily = keyof typeof variables.fonts;

export type Offsets = keyof typeof variables.offsets;

export default variables;
