import React from 'react';
import dynamic from 'next/dynamic';
import { Container } from './MenuList.styled';
import PotentiallyLink from '@/components/common/PotentiallyLink/PotentiallyLink';
import { LinkI } from '@/interfaces';
const Spoiler = dynamic(() => import('@/components/blocks/Spoiler/Spoiler'));

interface MenuListProps {
    text: string;
    href?: string;
    subLinks?: LinkI[];
}

const MenuList: React.FC<MenuListProps[]> = (links) => {
    return (
        <Container>
            {links.map((item, index) => (
                <li key={index}>
                    {item.subLinks ? (
                        <Spoiler key={index} header={<span> {item.text} </span>} headerBtn={false}>
                            <ul>
                                {item.subLinks.map((item, i) => (
                                    <li key={i}>{PotentiallyLink(item)}</li>
                                ))}
                            </ul>
                        </Spoiler>
                    ) : (
                        PotentiallyLink(item)
                    )}
                </li>
            ))}
        </Container>
    );
};

export default MenuList;
