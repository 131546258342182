import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ArticleSlugState {
    ru: string;
    en: string;
}

const initialState: ArticleSlugState = {
    ru: '',
    en: ''
};

const articleSlugSlice = createSlice({
    name: 'articleSlugs',
    initialState,

    reducers: {
        setArticleSlugs: (_, { payload }: PayloadAction<ArticleSlugState>) => payload
    }
});

export const { setArticleSlugs } = articleSlugSlice.actions;

export default articleSlugSlice.reducer;
