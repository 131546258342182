import React, { memo } from 'react';
import { default as NextImage, ImageProps as NextImageProps, StaticImageData } from 'next/image';
import BasePlaceholder from '/public/images/placeholder.jpg';
import { Container } from './Image.styled';
import { BaseUI } from '@/interfaces';
import useIsIE11 from '@/hooks/useIsIE11';

type ImageProps = Omit<NextImageProps, 'src'> & { src?: string | StaticImageData | null; simple?: boolean };

const defaultOption: NextImageProps = {
    src: BasePlaceholder,
    alt: '',
    quality: 90,
    lazyBoundary: '500px',
    placeholder: 'blur',
    blurDataURL: BasePlaceholder.blurDataURL,
    objectPosition: '50% 50%'
};

const Image: React.FC<ImageProps & BaseUI> = (props) => {
    const isIE11 = useIsIE11();
    const options = {
        ...defaultOption,
        ...props,
        src: props.src ?? defaultOption.src
    };

    return (
        <Container className={props.className}>
            {isIE11 || props.simple ? (
                <img
                    // @ts-ignore
                    src={options.src}
                    alt={options.alt}
                    style={{
                        fontFamily: `'object-fit: ${props.objectFit}; object-position: ${options.objectPosition};'`,
                        width: '100%',
                        height: '100%',
                        objectFit: props.objectFit,
                        objectPosition: options.objectPosition
                    }}
                />
            ) : (
                <NextImage {...options} />
            )}
        </Container>
    );
};

export default memo(Image);
