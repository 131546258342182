import { IMetrikaNewSample } from '@/interfaces';

export const Subscription: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/procedures/subscription': {
        form: {
            event: 'footer-click-subcribe',
            category: 'footer',
            actions: {}
        }
    }
};
