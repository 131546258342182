import { color, hover, mediaBreakpointDown } from '@/style/mixins';
import styled from 'styled-components';

export const Container = styled.div<{ isVisible: boolean }>`
    opacity: 0;
    pointer-events: none;
    ${({ isVisible }) =>
        isVisible &&
        `opacity: 1;
        pointer-events: all;`};
    z-index: 2;
    transition: opacity 0.4s ease-in-out;
    position: fixed;
    bottom: 80px;
    left: calc(100vw - 56px - var(--scrollbar-width) - 12px);
    padding: 0 12px 12px 0;

    ${mediaBreakpointDown('xl')} {
        left: unset;
        right: 0;
    }
`;

export const ScrollButton = styled.button`
    height: 56px;
    width: 56px;
    padding: 0;
    margin: 0;
    position: relative;
    background-color: ${color('white')};
    border: 1px solid ${color('blue')};
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.4s ease-in-out;

    ${hover(`
        background-color: ${color('blue')};

        &::before,
        &::after {
            border-color: ${color('white')};
        }
    `)}

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 13px;
        height: 0;
        border-top: 2px solid ${color('blue')};
        top: 60%;
        transition: border-color 0.4s ease-in-out;
    }

    &::before {
        transform-origin: left center;
        transform: translate(-50%, -50%) rotate(-45deg);
        left: 46%;
    }

    &::after {
        transform-origin: right center;
        transform: translate(50%, -50%) rotate(45deg);
        right: 46%;
    }
`;
