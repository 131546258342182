import React from 'react';
import { Container, TagContainerProps } from './Tag.styled';
import Link from 'next/link';
import { BaseUI } from '@/interfaces';

interface TagProps extends TagContainerProps, BaseUI {
    href?: string;
}

const Tag: React.FC<TagProps> = ({ href, children, className, big = false, gray = false, black = false, backgroundColor, textColor }) => {
    return href ? (
        <Link href={href} passHref prefetch={false}>
            <Container
                as={'a'}
                className={className}
                big={big}
                gray={gray}
                black={black}
                backgroundColor={backgroundColor}
                textColor={textColor}
            >
                {children}
            </Container>
        </Link>
    ) : (
        <Container className={className} big={big} gray={gray} black={black} backgroundColor={backgroundColor} textColor={textColor}>
            {children}
        </Container>
    );
};

export default Tag;
