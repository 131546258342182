import styled from 'styled-components';
import { color, font, offset, sideOffsets, mediaBreakpointUp, vw, hover } from '@/style/mixins';
import RectLink from '@/components/ui/RectLink/RectLink';

export const Container = styled.div`
    position: relative;
    background-color: ${color('blue')};
    color: ${color('white')};
    flex-grow: 1;
    ${font('h3')};
    ${sideOffsets()};
    ${offset('xxxl', 'padding-top')}
    ${offset('xxxl', 'padding-bottom')}

    ${mediaBreakpointUp('fhd')} {
        padding-right: ${vw(160)};
        padding-left: ${vw(160)};
    }

    & > div {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;

        ${mediaBreakpointUp('md')} {
            justify-content: flex-start;
        }

        ${mediaBreakpointUp('lg')} {
            gap: ${vw(20)};
        }

        a {
            border: 1px solid ${color('white', 0.2)};
            border-radius: 4px;
            width: 45%;
            display: flex;
            justify-content: center;
            align-items: center;

            ${mediaBreakpointUp('md')} {
                width: fit-content;
            }

            svg {
                transition: opacity 0.4s;
            }

            ${hover(`
            svg{
                opacity:0.5;
            }
        `)};
        }
    }
`;

export const ErrorPageTitle = styled.h1`
    ${offset('l', 'margin-bottom')};
`;

export const ErrorPageText = styled.p`
    ${font('text1-l')};
    ${offset('l', 'margin-bottom')}
`;

export const ErrorPageButton = styled(RectLink)`
    color: ${color('white')};
    border-color: ${color('white')};
    width: fit-content;
    ${hover(`border-color: ${color('white', 0.5)}`)};
`;
