import { DefaultTheme } from 'styled-components';

export const baseTheme: DefaultTheme = {
    main: {
        background: 'blue',
        text: 'white'
    },
    secondary: {
        background: 'blue2',
        text: 'white'
    },
    headerBorder: 'currentColor',
    hover: 'blue'
};

export const rosneftTheme: DefaultTheme = {
    main: {
        background: 'yellow2',
        text: 'black'
    },
    secondary: {
        background: 'orange',
        text: 'white'
    },
    headerBorder: 'yellow2',
    hover: 'orange'
};

export const interRAOTheme: DefaultTheme = {
    main: {
        background: 'orange2',
        text: 'white'
    },
    secondary: {
        background: 'darkBlue',
        text: 'white'
    },
    headerBorder: 'orange2',
    hover: 'orange2'
};

export const gosZakupki: DefaultTheme = {
    main: {
        background: 'blue',
        text: 'white'
    },
    secondary: {
        background: 'blue2',
        text: 'white'
    },
    headerBorder: 'blue',
    hover: 'blue'
};

// Темы для фреймов (процедуры)
export const portalKhabarovskFrameTheme: DefaultTheme = {
    main: {
        background: 'blue4',
        text: 'white'
    },
    secondary: {
        background: 'blue5',
        text: 'white'
    },
    headerBorder: 'currentColor',
    hover: 'blue4'
};

export const silovyiMachineFrameTheme: DefaultTheme = {
    main: {
        background: 'turquoise',
        text: 'white'
    },
    secondary: {
        background: 'turquoise2',
        text: 'white'
    },
    headerBorder: 'currentColor',
    hover: 'turquoise'
};

export const tomskFrameTheme: DefaultTheme = {
    main: {
        background: 'blue',
        text: 'white'
    },
    secondary: {
        background: 'blue6',
        text: 'white'
    },
    headerBorder: 'currentColor',
    hover: 'blue'
};

export const jtkFrameTheme: DefaultTheme = {
    main: {
        background: 'gray2',
        text: 'black'
    },
    secondary: {
        background: 'black',
        text: 'black'
    },
    headerBorder: 'currentColor',
    hover: 'blue'
};
