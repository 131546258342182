import { BaseOffsetsUI } from '@/interfaces';
import { Colors } from '@/style/colors';
import { allBreakpointValue, color, offset } from '@/style/mixins';
import styled from 'styled-components';

export const Container = styled.div<
    BaseOffsetsUI & { circleColor?: Colors; loadingAnimation?: boolean; spinnerAnimation?: boolean; alignCenter: boolean }
>`
    position: relative;
    ${({ topOffset }) => topOffset && offset(topOffset, 'padding-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')};
    font-size: 0;
    ${({ alignCenter }) => alignCenter && `text-align: center`};

    .spinner {
        filter: blur(0);
        ${allBreakpointValue('width', 58, 46, 32, 20, 20)};
        ${allBreakpointValue(' height', 58, 46, 32, 20, 20)};
        transform: ${({ loadingAnimation }) => loadingAnimation && 'rotate(-90deg)'};

        ${({ spinnerAnimation }) => spinnerAnimation && 'animation: rotate 2s linear infinite'};

        & .path {
            stroke-width: 2px;
            stroke-linecap: round;
            stroke: ${({ theme }) => color(theme.main.background)};
            animation: ${({ loadingAnimation, spinnerAnimation }) => (loadingAnimation ? 'loading' : spinnerAnimation && 'spinner')} 1.5s
                linear infinite;
        }
    }

    @keyframes loading {
        from {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        to {
            stroke-dasharray: 130, 150;
            stroke-dashoffset: 0;
        }
    }

    @keyframes spinner {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }
`;
