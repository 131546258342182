import { useRouter } from 'next/router';
import { detect } from 'detect-browser';

export const useCheckBrowserVersion = async () => {
    const router = useRouter();

    const browser = detect();
    const browserName = browser?.name?.toLowerCase();
    const browserVersion = Number(browser?.version?.match(/\d+/)?.[0]);

    if (!browserName || !browserVersion) return;

    if (
        (browserName === 'ie' && browserVersion < 11) ||
        (browserName === 'chrome' && browserVersion < 64) ||
        (browserName === 'edge' && browserVersion < 79) ||
        (browserName === 'safari' && browserVersion < 12) ||
        (browserName === 'firefox' && browserVersion < 67) ||
        (browserName === 'opera' && browserVersion < 51)
    ) {
        await router.push('/api/notSupported');
    }
};
