/**
 * @description Сэмплы компонента Tags
 */
const SERVICE_BLOCKS_INTERACTION_TAGS = {
    tags: {
        event: 'services-block-interaction',
        category: 'services',
        actions: [{ key: 'click-selector', value: null }]
    }
};
const PROPERTY_SALE_INTERACTION_TAGS = {
    tags: {
        event: 'property-sale-interaction',
        category: 'property-sale',
        actions: [{ key: 'property-customer-type-selector', value: null }]
    }
};
/**
 * @description Сэмплы компонента ServicesCardsInner
 */
const SERVICE_BLOCKS_INTERACTION_CARDS = {
    mainCard: {
        event: 'services-block-interaction',
        category: 'services',
        actions: [{ key: 'click-block-service', value: null }]
    },
    cards: {
        event: 'services-block-interaction',
        category: 'services',
        actions: [{ key: 'click-service', value: null }]
    } // В будущем надо будет добавить еще клик по кнопке в карточке
};
/**
 * @description Экшены кнопок
 */
const CLICK_BLOCK_ACTION = [
    { key: 'click-block', value: null },
    { key: 'placement_id', value: 'eudcation main' }
];

/**
 * @description Экшены форм
 */
const FORMS_REQUEST_SUCCESS_ACTIONS = [
    { key: 'success-request', value: true },
    { key: 'form_id', value: null },
    { key: 'request_id', value: null }
];

const FORMS_LEAVE_QUESTION_ACTIONS = [
    { key: 'click-leave-question', value: true },
    { key: 'form_id', value: null },
    { key: 'request_id', value: null }
];

const FORMS_COURSE_ID_ACTIONS = [
    { key: 'form_id', value: null },
    { key: 'request_id', value: null },
    { key: 'course_id', value: null }
];

const COURSE_ID_REGISTRATE_ACTIONS = [{ key: 'click-registrate', value: null }];

const FORMS_COURSE_ID_REGISTRATE_ACTIONS = [
    ...COURSE_ID_REGISTRATE_ACTIONS,
    { key: 'form_id', value: null },
    { key: 'request_id', value: null },
    { key: 'course_id', value: null }
];

/**
 * @description Сэмплы форм
 */
const ROSNEFT_FORMS_SAMPLES = {
    form: {
        event: 'rosneft-form-request-success',
        category: 'rosneft',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const ROSNEFT_PARTNERSHIP_FORMS_SAMPLES = {
    form: {
        event: 'rosneft-form-request-success-for-partnership',
        category: 'rosneft',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const INTERRAO_FORMS_SAMPLES_INTERACTION = {
    form: {
        event: 'inter-rao-interaction',
        category: 'inter-rao',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const INTERRAO_FORMS_SAMPLES_SUCCESS = {
    form: {
        event: 'inter-rao-request-success',
        category: 'inter-rao',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const CLIENT_CENTER_FORMS_SAMPLES = {
    form: {
        event: 'client-center-request-success',
        category: 'client-center',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const SERVICES_BLOCK_FORMS_SAMPLES = {
    form: {
        event: 'services-block-request-success',
        category: 'services',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const FINANCE_BLOCK_FORMS_SAMPLES = {
    form: {
        event: 'finance-block-request-success',
        category: 'finance',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const SERVICE_BLOCK_FORMS_SAMPLES = {
    form: {
        event: 'services-block-interaction',
        category: 'services',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const EDUCATION_BLOCK_INTERACTION = {
    formCourseId: {
        event: 'education-block-interaction',
        category: 'education',
        actions: FORMS_COURSE_ID_ACTIONS
    },
    bannerButtonText: {
        event: 'education-block-interaction',
        category: 'education',
        actions: CLICK_BLOCK_ACTION
    },
    actualCards: {
        event: 'education-block-interaction',
        category: 'education',
        actions: CLICK_BLOCK_ACTION
    }
};

const EDUCATION_COURSE_BLOCK_INTERACTION = {
    leadButtonCourseId: {
        event: 'education-block-interaction',
        category: 'education',
        actions: COURSE_ID_REGISTRATE_ACTIONS
    },
    formRegistration: {
        event: 'education-block-interaction',
        category: 'education',
        actions: FORMS_COURSE_ID_REGISTRATE_ACTIONS
    },
    formLeaveQuestion: {
        event: 'education-block-interaction',
        category: 'education',
        actions: FORMS_LEAVE_QUESTION_ACTIONS
    }
};

const CALLBACK_FORMS_SAMPLES = {
    form: {
        event: 'callback-call-request-success',
        category: 'callback',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const ABOUT_COMPANY_FORMS_SAMPLES = {
    form: {
        event: 'about-company-form-request',
        category: 'about-company',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const MAIL_SUBSCRIBE_SAMPLES = {
    form: {
        event: 'about-company-mailing-subscribed',
        category: 'about-company',
        actions: FORMS_REQUEST_SUCCESS_ACTIONS
    }
};

const CONTACT_MANAGER_FORM_SAMPLES = {
    form: {
        event: '223-fz-click-contact-manager',
        category: '223-fz',
        actions: {}
    }
};

const BANK_GUARANTEE_FORM_SAMPLES = {
    allForm: {
        event: 'bg_submit_all_forms',
        category: '',
        actions: {}
    },
    expertForm: {
        event: 'bg_submit_expert',
        category: '',
        actions: {}
    },
    guaranteeForm: {
        event: 'bg_submit_guarantee',
        category: '',
        actions: {}
    }
};

/**
 * @description Сэмплы blockWithCards
 */
const CLIENTS_BLOCK_CARDS_ACTIONS = [{ key: 'click-block', value: null }];

const CLIENTS_BLOCK_CARDS_SAMPLES = {
    blockWithCards: {
        event: 'client-center-request-success',
        category: 'client-center',
        actions: CLIENTS_BLOCK_CARDS_ACTIONS
    }
};

const UVS_BLOCK_CARDS_SAMPLES = {
    blockWithCards: {
        event: 'uvs-interaction',
        category: 'uvs',
        actions: CLIENTS_BLOCK_CARDS_ACTIONS
    }
};

/** */
const SAMPLES = {
    SERVICE_BLOCKS_INTERACTION_TAGS,
    PROPERTY_SALE_INTERACTION_TAGS,
    SERVICE_BLOCKS_INTERACTION_CARDS,
    ROSNEFT_FORMS_SAMPLES,
    ROSNEFT_PARTNERSHIP_FORMS_SAMPLES,
    INTERRAO_FORMS_SAMPLES_INTERACTION,
    INTERRAO_FORMS_SAMPLES_SUCCESS,
    CLIENT_CENTER_FORMS_SAMPLES,
    SERVICES_BLOCK_FORMS_SAMPLES,
    FINANCE_BLOCK_FORMS_SAMPLES,
    SERVICE_BLOCK_FORMS_SAMPLES,
    EDUCATION_BLOCK_INTERACTION,
    EDUCATION_COURSE_BLOCK_INTERACTION,
    CALLBACK_FORMS_SAMPLES,
    ABOUT_COMPANY_FORMS_SAMPLES,
    MAIL_SUBSCRIBE_SAMPLES,
    CLIENTS_BLOCK_CARDS_SAMPLES,
    UVS_BLOCK_CARDS_SAMPLES,
    CONTACT_MANAGER_FORM_SAMPLES,
    BANK_GUARANTEE_FORM_SAMPLES
};

export default SAMPLES;
