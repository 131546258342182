import React, { useState } from 'react';
import { Container, ScrollButton } from './ScrollTopButton.styled';
import { useWindowEvent } from '@/hooks/useWindowEvent';
import debounce from 'lodash.debounce';

interface ScrollTopButtonProps {}

const ScrollTopButton: React.FC<ScrollTopButtonProps> = () => {
    const [isVisible, setVisible] = useState<boolean>(false);

    const buttonClickHandler = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    };

    const debouncedScrollHandler = debounce(() => setVisible(window.scrollY >= window.innerHeight), 100);
    useWindowEvent('scroll', debouncedScrollHandler);

    return (
        <Container isVisible={isVisible}>
            <ScrollButton onClick={buttonClickHandler} />
        </Container>
    );
};

export default ScrollTopButton;
