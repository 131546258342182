import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const InterRao: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/inter_rao/ekosreda/poisk_zakupki': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS,
    '/inter_rao/ekosreda/proverka_partnyorov': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS,
    '/inter_rao/ekosreda/poisk_tekhnicheskogo_zadaniya': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // работает
    '/inter_rao/ekosreda/audit': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // работает
    '/inter_rao/ekosreda/poisk_partnyorov_dlya_kollektivnogo_uchastiya': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // работает
    '/inter_rao/ekosreda/accreditation': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // Формы нет
    '/inter_rao/ekosreda/zayavit_ob_importozameschenii': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // работает
    '/inter_rao/ekosreda/programma_vyraschivaniya': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // работает
    '/inter_rao/ekosreda/e-signature': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // работает
    '/inter_rao/ekosreda/edo': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // работает
    '/inter_rao/ekosreda/tkp': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // работает
    '/inter_rao/ekosreda/edinoe_prezentatsionnoe_okno_tekhnologiy': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // работает
    '/inter_rao/help': SAMPLES.INTERRAO_FORMS_SAMPLES_SUCCESS, // Верятно опечатка в ТЗ, сделал форму
    '/inter_rao/service': SAMPLES.SERVICE_BLOCKS_INTERACTION_TAGS, // работает
    '/inter_rao/service/e-signature': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/fast-accreditation': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/eruz': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/134': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/icwe': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/accompany-tenders': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/edo': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/factoring': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/credit-on-contract': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/bg': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES, // работает
    '/inter_rao/service/credit': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES // работает
};
