import React from 'react';
import { Container, FooterAddress, FooterContactsDocs, FooterContactsPhones, FooterContactsSocials } from './FooterContacts.styled';
import { FooterI } from '@/interfaces';
import LinkDown from '@/components/ui/LinkDown/LinkDown';
import { SvgElements } from '@/helpers/icons';

interface FooterContactsProps extends FooterI {
    className?: string;
}

const createPhoneLink = (href: string, template: string) => {
    return template.replace('#', href);
};

const FooterContacts: React.FC<FooterContactsProps> = ({ phones, address, docs, socials, className }) => {
    const phoneLinkTemplate = 'tel:#';

    return (
        <Container className={className}>
            {phones && (
                <FooterContactsPhones>
                    {phones.map(({ href, text }, i) => (
                        <a href={createPhoneLink(href, phoneLinkTemplate)} key={i}>
                            {text}
                        </a>
                    ))}
                </FooterContactsPhones>
            )}

            {address && <FooterAddress dangerouslySetInnerHTML={{ __html: address }} />}

            <div>
                {socials && (
                    <FooterContactsSocials>
                        {socials.map(({ href, name, text }, i) => (
                            <a href={href} target="_blank" rel="noreferrer" key={i}>
                                <span>{SvgElements[name]}</span>
                                {!!text && <span>{text}</span>}
                            </a>
                        ))}{' '}
                    </FooterContactsSocials>
                )}

                {docs && (
                    <FooterContactsDocs>
                        {docs.map((link, i) => (
                            <LinkDown {...link} key={i} />
                        ))}{' '}
                    </FooterContactsDocs>
                )}
            </div>
        </Container>
    );
};

export default FooterContacts;
