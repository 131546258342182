import { getContentApiRoute } from '@/lib/api';

export const getCustomersList = async () => {
    let customers: [] = [];
    const customersPromise = getContentApiRoute('/customers');

    try {
        const { data } = await customersPromise;

        if (data?.items) {
            customers = data.items.map((item: { slug: string; alias: string; icon?: string }) => {
                return {
                    alias: (!!item.alias && item.alias.trim().replace(/\s/g, '').split(',')) || [''],
                    slug: item.slug || '',
                    icon: item.icon || ''
                };
            });
        }
    } catch (e) {}

    return customers;
};
