import styled, { css } from 'styled-components';
import { font, color, vw, mediaBreakpointUp, offset, allBreakpointValue, svgMaskStyle, mediaBreakpointDown } from '@/style/mixins';
import Image from '@/components/common/Image/Image';
import { Colors } from '@/style/colors';
import Tag from '@/components/ui/Tag/Tag';
import { Container as ModalBtn } from '@/components/ui/ModalBtn/ModalBtn.styled';
import { Container as Picture } from '@/components/common/Picture/Picture.styled';
import { Card1ContainerProps } from '@/interfaces';

export const SvgMask = styled.div`
    ${svgMaskStyle};

    svg {
        fill: ${color('maskColor1')};
    }
`;

export const Card1More = styled.div<{ background: boolean; textColor?: Colors }>`
    position: relative;
    display: flex;
    align-items: center;
    ${allBreakpointValue('height', 80, 60, 54, 50, 50)};
    padding-left: 5px;
    padding-right: 20px;
    ${font('button')};
    background-color: ${({ background }) => (background ? color('blue') : 'none')};

    ${mediaBreakpointUp('md')} {
        padding-left: 25px;
    }

    ${mediaBreakpointUp('xl')} {
        padding-left: 0;
        padding-right: 25px;
    }

    ${mediaBreakpointUp('fhd')} {
        padding-right: 35px;
    }

    ${({ background }) =>
        background &&
        css`
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                border-bottom: solid ${color('blue')};
                border-left: solid transparent;
                ${allBreakpointValue('border-bottom-width', 80, 60, 54, 50, 50)};
                ${allBreakpointValue('border-left-width', 72, 54, 48, 45, 45)};
                transform: translateX(-99%);
            }
        `}

    svg {
        display: block;
        width: 12px;
        height: 12px;
        margin-left: 10px;
        stroke: currentColor;
        transition: transform 0.3s ease-in-out;

        ${mediaBreakpointUp('xl')} {
            width: ${vw(12, 'xl')};
            height: ${vw(12, 'xl')};
        }
    }

    ${ModalBtn} {
        color: ${({ textColor }) => (textColor ? color(textColor) : color('white'))};
    }
`;

export const Title2 = styled.div`
    margin-top: 15px;
`;
export const Title = styled.h3<{ VideoCard?: boolean }>`
    ${({ VideoCard }) => VideoCard && 'visibility:hidden;opacity:0;'};

    transition: visibility 0.3s ease-in, opacity 0.3s ease-in;
`;
export const Card1Mask = styled.div<{ colorBg?: Colors }>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: ${({ colorBg, theme }) => (colorBg ? color('white') : color(theme.main.background))};
    pointer-events: none;
    transition: background-color 0.3s ease-in-out;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        border-bottom-style: solid;
        border-left-style: solid;
        border-bottom-color: transparent;
        border-left-color: ${({ colorBg, theme }) => (colorBg ? color('white') : color(theme.main.background))};
        transform: translateX(99%);
        transition: border-left-color 0.3s ease-in-out;
    }
`;

export const Card1TextBottom = styled.div<{ dateColor?: Colors; withArrow?: boolean }>`
    margin-top: auto;
    transition: color 0.3s ease-in-out;
    ${font('text1-l')};

    color: ${({ dateColor }) => (dateColor ? color(dateColor) : color('black'))};
    opacity: 0.5;

    ${({ withArrow }) =>
        withArrow &&
        `
        opacity: 1;
        display: inline-block;

        svg {
            width: 10px;
            height: 10px;
            stroke: currentColor;
            margin-left: 10px;
            vertical-align: middle;

            ${mediaBreakpointUp('fhd')} {
                width: ${vw(18, 'fhd')};
                height: ${vw(18, 'fhd')};
            }
        }
    `};
`;

export const Container = styled.a<Card1ContainerProps & { type?: string; flippedTags?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    ${offset('m', 'padding')};
    background-color: ${({ imageBackground, bgColor }) => (imageBackground ? 'transparent' : color(bgColor ? bgColor : 'white'))};
    color: ${({ textColor }) => (textColor ? color(textColor) : color('white'))};
    border-radius: 6px;
    overflow: hidden;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    ${({ backgroundImage }) =>
        backgroundImage &&
        `
            background-image: url(${backgroundImage});
            background-color: ${color('blue', 0.9)};
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
    `};

    ${mediaBreakpointUp('xl')} {
        ${({ isHover, textHover, bgHover, maskHover, theme }) =>
            isHover &&
            `
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

            ${Card1More} {
                svg {
                    transition: transform 0.3s ease-in-out;
                }
            }

            ${Card1Mask} {
                transition: background-color 0.3s ease-in-out;

                &::after {
                    transition: border-left-color 0.3s ease-in-out;
                }
            }

            &:hover {
                color: ${textHover ? color(textHover) : color(theme.secondary.text)};
                background-color: ${bgHover ? color(bgHover) : color(theme.hover)};

                ${Card1More} {
                    svg {
                        transform: translateX(5px);
                    }
                }
                h3 {
                    visibility: visible;
                    opacity: 1;
                }
                ${Card1Mask} {
                    background-color: ${maskHover && color(maskHover)} !important;

                    &::after {
                        border-left-color: ${maskHover && color(maskHover)} !important;
                    }
                }

                ${Card1TextBottom} {
                    color: ${color('white', 0.5)};
                }
            }
        `};
    }

    ${({ flippedTags }) =>
        flippedTags &&
        `
        ${Title} {
            margin-bottom: 10px;
        }

        ${Card1TextBottom} {
            margin-top: 0;
            ${allBreakpointValue('margin-bottom', 40, 30, 20, 15, 15)}
        }

        ${Card1Tags} {
            order: 9;
            margin-top: auto;
            margin-right: -5px;
            overflow: hidden;

            ${Card1Tag} {
                white-space: nowrap;
                margin-right: 5px;

                @media(min-width: 2100px) {
                    margin-bottom: ${vw(10, 'fhd')};
                }
            }

            ${mediaBreakpointDown('xs')} {
                max-height: 67px;
                @media(min-width: 370px) {
                    max-height: 75px;
                }
            }
            max-height: 75px;
            ${mediaBreakpointUp('md')} {
                max-height: 75px;
            }
            ${mediaBreakpointUp('xl')} {
                max-height: 35px; // 72
            }
            ${mediaBreakpointUp('xxl')} {
                max-height: 75px;
            }
            ${mediaBreakpointUp('fhd')} {
                max-height: 91px;
            }

            @media(min-width: 2100px) {
                max-height: ${vw(88, 'fhd')};
            }
        }
    `}

    h3 {
        position: relative;
        z-index: 1;
        margin-right: 70px;

        ${({ reverseFonts }) =>
            reverseFonts
                ? css`
                      ${allBreakpointValue('margin-bottom', 16, 12, 10, 24, 28)};
                      ${font('text1-l')};
                  `
                : css`
                      ${allBreakpointValue('margin-bottom', 16, 12, 10, 10, 10)};
                      ${font('h5-b')};
                  `};

        ${mediaBreakpointUp('md')} {
            margin-right: ${vw(90, 'md')};
        }

        ${mediaBreakpointUp('xl')} {
            margin-right: ${({ backgroundTitle }) => (backgroundTitle ? vw(120, 'xl') : vw(140, 'xl'))};
        }

        ${({ backgroundTitle, fullWidthTitle }) =>
            fullWidthTitle &&
            !backgroundTitle &&
            css`
                margin-right: 0 !important;
            `}
    }

    div {
        ${font('text1-l')};
    }

    p {
        margin-right: 30px;
        ${({ reverseFonts }) => (reverseFonts ? font('h5-b') : font('text1-l'))};

        ${mediaBreakpointUp('xl')} {
            margin-right: ${({ type }) => (type === 'ad' ? vw(75, 'xl') : vw(60, 'xl'))};
        }
    }

    ${Card1Mask} {
        ${({ type }) =>
            type === 'news'
                ? css`
                      ${allBreakpointValue('width', 400, 300, 265, 215, 215)};
                      ${allBreakpointValue('height', 130, 110, 105, 110, 110)};
                  `
                : css`
                      ${allBreakpointValue('width', 375, 280, 250, 220, 220)};
                      ${allBreakpointValue('height', 160, 120, 105, 115, 115)};
                  `}

        &::after {
            ${({ type }) =>
                type === 'news'
                    ? css`
                          ${allBreakpointValue('border-bottom-width', 130, 110, 105, 110, 110)};
                          ${allBreakpointValue('border-left-width', 120, 95, 90, 95, 100)};
                      `
                    : css`
                          ${allBreakpointValue('border-bottom-width', 160, 120, 105, 115, 115)};
                          ${allBreakpointValue('border-left-width', 135, 95, 90, 100, 100)};
                      `}
        }
    }
    ${ModalBtn} {
        ${({ fullModalCard }) =>
            fullModalCard
                ? css`
                      position: initial;
                      border: none;
                      height: 100%;
                      h3,
                      p {
                          text-align: left;
                      }
                  `
                : ''}
    }

    &.simple-widget {
        & > h3 {
            margin-right: 0;
        }

        & > p {
            max-width: none;
        }

        ${Card1More} {
            left: 0;
            right: initial;

            padding-left: 14px;

            ${mediaBreakpointUp('xxl')} {
                padding-left: 16px;
            }

            ${mediaBreakpointUp('fhd')} {
                padding-left: 20px;
            }
        }
    }

    ${Picture} {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
    }

    ${({ partitions }) =>
        partitions &&
        css`
            border-radius: 8px;
            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: auto;
                color: ${color('white')};
                h3 {
                    margin-bottom: 0px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -moz-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    line-clamp: 3;
                    ${font('h5-l')}
                    font-weight: 450;
                }
            }

            svg {
                stroke: ${color('white')};
                margin-left: 15px;
                ${allBreakpointValue('width', 13, 9, 8, 8, 7)};
            }
        `};
`;

export const Card1Background = styled(Image)<{ bg?: Colors }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none;
    ${({ bg }) => bg && `background-color: ${color(bg)}`};
`;

export const Card1Tag = styled(Tag)`
    position: absolute;
    ${offset('m', 'top')};
    ${offset('m', 'right')};
`;

export const Card1Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;

    ${Card1Tag} {
        position: relative;
        top: auto;
        right: auto;
        margin-top: 5px;

        &:not(:last-of-type) {
            margin-right: 4px;

            ${mediaBreakpointUp('xl')} {
                margin-right: 5px;
            }
        }
    }
`;

export const Card1Play = styled.div`
    position: absolute;
    ${offset('m', 'top')};
    ${offset('m', 'right')};
    z-index: 1;
    display: flex;
    width: 40px;
    height: 40px;
    background-color: ${color('white')};
    border-radius: 50%;

    ${mediaBreakpointUp('xl')} {
        width: ${vw(34, 'xl')};
        height: ${vw(34, 'xl')};
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        border-top: solid transparent;
        border-bottom: solid transparent;
        border-left: solid ${color('blue2')};
        ${allBreakpointValue('border-top-width', 6, 4.5, 4, 6, 6)};
        ${allBreakpointValue('border-bottom-width', 6, 4.5, 4, 6, 6)};
        ${allBreakpointValue('border-left-width', 10, 7.5, 6.5, 10, 10)};
        transform: translate(-50%, -50%);
    }
`;

export const Card1Icons = styled.div`
    margin-top: auto;
    ${offset('xxs', 'padding-top')};
    display: flex;
`;
export const Card1FileName = styled.div`
    margin-top: auto;
    ${font('text2-l')};
    ${offset('xxs', 'padding-top')};
    display: flex;
`;

export const Card1Icon = styled.div`
    &:not(:first-child) {
        ${offset('xxs', 'margin-left')};
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
`;

export const Partition = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 22px;
    padding-right: 22px;
    background-color: ${color('blue')};
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: ${color('white')};
`;

export const Card1Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    ${Card1TextBottom} {
        flex-shrink: 0;
        margin-top: 0;
    }

    ${Card1Tags} {
        flex-direction: row-reverse;

        ${Card1Tag} {
            margin-right: 0;
            margin-left: 5px;
        }
    }
`;
