import { SvgElements } from '@/helpers/icons';
import Button from '../Button/Button';
import {
    BrowserList,
    BrowserListItem,
    BrowserListLink,
    BrowserListLinkTitle,
    Container,
    Description,
    Title
} from './NotSupportedIE.styled';
import useTranslation from '@/hooks/useTranslation';
import useLocalStorage from '@/hooks/useLocaleStorage';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import useIsIE11 from '@/hooks/useIsIE11';

const browsers = [
    {
        name: 'Opera',
        icon: 'opera',
        href: 'https://www.opera.com/ru'
    },
    {
        name: 'Google Chrome',
        icon: 'chrome',
        href: 'https://www.google.com/intl/ru_ru/chrome/'
    },
    {
        name: 'Microsoft Edge',
        icon: 'edge',
        href: 'https://www.microsoft.com/ru-ru/edge?form=MA13FJ'
    },
    {
        name: 'Mozilla Firefox',
        icon: 'firefox',
        href: 'https://www.mozilla.org/ru/firefox/new/'
    },
    {
        name: 'Safari',
        icon: 'safari',
        href: 'https://www.apple.com/ru/safari/'
    }
];

const storageKey = 'TEK_USE_APP_WITH_IE';

const NotSupportedIE = () => {
    const { t } = useTranslation('common');
    const isIE = useIsIE11();

    // isVisible фиксит эффект мерцания сообщения,
    // при первой загрузке, либо при перезагрузке страницы
    // когда isUseAppWithIE === true.
    const [isVisible, setIsVisible] = useState(false);
    const [isUseAppWithIE, setIsUseAppWithIE] = useLocalStorage(storageKey, false);

    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const container = containerRef.current;

        if (!container) return;

        if (isIE && !isUseAppWithIE) {
            disableBodyScroll(container);
        } else {
            enableBodyScroll(container);
        }

        return () => clearAllBodyScrollLocks();
    }, [isUseAppWithIE, isIE]);

    useEffect(() => setIsVisible(!isUseAppWithIE), [isUseAppWithIE]);

    const clickHandler = () => setIsUseAppWithIE(true);

    return (
        <>
            {isIE && (
                <div ref={containerRef}>
                    <CSSTransition in={isVisible} timeout={250} unmountOnExit className="not-supported">
                        <Container>
                            <Title dangerouslySetInnerHTML={{ __html: t('notSupported.title') }} />

                            <Description dangerouslySetInnerHTML={{ __html: t('notSupported.description') }} />

                            <BrowserList>
                                {browsers.map(({ name, icon, href }) => (
                                    <BrowserListItem key={name}>
                                        <BrowserListLink href={href} target="_blank">
                                            {SvgElements[icon]}
                                            <BrowserListLinkTitle>{name}</BrowserListLinkTitle>
                                        </BrowserListLink>
                                    </BrowserListItem>
                                ))}
                            </BrowserList>

                            <Description>
                                <Button textColor="white" onClick={clickHandler}>
                                    {t('notSupported.btn')}
                                </Button>
                            </Description>
                        </Container>
                    </CSSTransition>
                </div>
            )}
        </>
    );
};

export default NotSupportedIE;
