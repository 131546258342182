export function getLocalStorage<T>(key: string, initialValue: T): T {
    if (typeof window === 'undefined') {
        return initialValue;
    }

    try {
        const item = window.localStorage.getItem(key);

        return item ? JSON.parse(item) : initialValue;
    } catch (error) {
        console.log(error);

        return initialValue;
    }
}
