import styled, { css } from 'styled-components';
import Collapse from '@kunukn/react-collapse';
import { font, offset } from '@/style/mixins';

export const Container = styled.div`
    position: relative;
`;
export const SpoilerHead = styled.div`
    position: relative;

    display: flex;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
`;
export const SpoilerHeadBtn = styled.div<{ isOpen?: boolean; arrowIcon?: boolean }>`
    display: flex;
    align-items: center;

    ${({ arrowIcon }) =>
        !arrowIcon
            ? css`
                  position: relative;
                  margin-left: auto;
                  width: 13px;
                  height: 13px;
                  &:before,
                  &:after {
                      content: '';
                      display: block;
                      position: absolute;
                      width: 100%;
                      height: 2px;
                      background: currentColor;
                      transition: transform 0.3s ease-in-out;
                      top: calc(50% - 1px);
                  }
              `
            : css`
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);

                  svg {
                      display: block;
                      width: 18px;
                      height: 18px;
                      stroke: currentcolor;
                      transition: transform 0.3s ease-in-out;
                  }
              `}

    &:before {
        transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg) scale(0)' : 'rotate(0) scale(1)')};
    }
    &:after {
        transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(90deg)')};
    }

    svg {
        transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
    }
`;
export const SpoilerHeadText = styled.div`
    ${offset('xs', 'margin-right')}
    ${font('text2-b')}
`;

export const SpoilerBody = styled(Collapse)`
    transition: height 0.3s ease-in-out;
`;
