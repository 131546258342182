import { sendMetrik } from '@/helpers';
import { IMetrikaNewSample } from '@/interfaces';
import pagePathes from '@/metriks/pagePathes';

/**
 * @description Собираем готовые шаблоны для метрики по адресам страниц
 */
export const METRIKS_SAMPLES: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    ...pagePathes
};

export const submitMetriksForm = (path: string, formId: string | undefined) => {
    if (!METRIKS_SAMPLES[path]?.form) return;
    findAndChangeActionValue(METRIKS_SAMPLES[path]?.form, 'form_id', formId ?? null);
    const reducedActions = reduceActions(METRIKS_SAMPLES[path]?.form);

    sendMetrik(METRIKS_SAMPLES[path]?.form?.event, METRIKS_SAMPLES[path]?.form?.category, {
        ...reducedActions
    });
};

/**
 * @description Метод отправки метрики для простых форм, без дополнительных параметров(action)
 */
export const submitMetriksSimpleForm = (path: string) => {
    if (!METRIKS_SAMPLES[path]?.form) return;
    sendMetrik(METRIKS_SAMPLES[path]?.form?.event, METRIKS_SAMPLES[path]?.form?.category, {});
};

/**
 * @description Метод-конструктор для метрики
 */
export const sendMetriksConstructor = (
    path: string,
    field: string,
    changeable: {
        neededKey: string | undefined;
        newValue: string | null | boolean | undefined;
    }[]
) => {
    if (!METRIKS_SAMPLES[path]?.[field]) return;
    changeable.map((item) => {
        findAndChangeActionValue(METRIKS_SAMPLES[path]?.[field], item.neededKey, item.newValue ?? null);
    });
    const reducedActions = reduceActions(METRIKS_SAMPLES[path]?.[field]);

    sendMetrik(METRIKS_SAMPLES[path]?.[field]?.event, METRIKS_SAMPLES[path]?.[field]?.category, { ...reducedActions });
};

/**
 * @description Метод, который разворачивает массив объектов с полями ключ-значение в один объект
 */
export const reduceActions = (sample: IMetrikaNewSample | undefined): { [key: string]: string } | undefined => {
    if (sample && Array.isArray(sample.actions))
        //@ts-ignore
        return sample.actions.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
};

/**
 * @description Метод подстановки значения под нужный ключ
 */
export const findAndChangeActionValue = (
    sample: IMetrikaNewSample | undefined,
    neededKey: string | undefined,
    newValue: string | null | boolean
) => {
    if (!Array.isArray(sample?.actions)) return;
    const neededAction = sample?.actions?.find((action) => action.key === neededKey);
    if (neededAction && newValue) neededAction.value = newValue;
};
