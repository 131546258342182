import React from 'react';
import { Container } from './CircularAnimation.styled';
import { Colors } from '@/style/colors';
import { BaseOffsetsUI, BaseUI } from '@/interfaces';

interface CircularAnimationProps extends BaseOffsetsUI, BaseUI {
    circleColor?: Colors;
    loadingAnimation?: boolean;
    spinnerAnimation?: boolean;
    alignCenter?: boolean;
}

const CircularAnimation: React.FC<CircularAnimationProps> = ({
    className,
    circleColor,
    loadingAnimation,
    spinnerAnimation,
    topOffset,
    bottomOffset,
    alignCenter = false
}) => {
    return (
        <Container
            className={className}
            circleColor={circleColor}
            loadingAnimation={loadingAnimation}
            spinnerAnimation={spinnerAnimation}
            topOffset={topOffset}
            bottomOffset={bottomOffset}
            alignCenter={alignCenter}
        >
            <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" />
            </svg>
        </Container>
    );
};

export default CircularAnimation;
