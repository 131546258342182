import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const Education: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/education': {
        ...SAMPLES.EDUCATION_BLOCK_INTERACTION
    },
    '/education/courses/[id]': {
        ...SAMPLES.EDUCATION_COURSE_BLOCK_INTERACTION
    },
    '/education/expert-articles': SAMPLES.EDUCATION_BLOCK_INTERACTION
};
