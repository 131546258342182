import { MouseEvent, useEffect, useRef } from 'react';
import { Container, DecorLine, LoadingBtn } from './WebimLoadingBtn.styled';
import { useRouter } from 'next/router';

// webim button generation date: 2023-01-16 version: 10.4.136

const MAX_COUNT = 40;
let loadingBtnIsHidden = false;

export const WebimLoadingBtn = () => {
    const loadingBtnRef = useRef<HTMLDivElement | null>(null);
    const { asPath } = useRouter();

    const clickHandler = (evt: MouseEvent<HTMLDivElement>) => {
        const delay = 500;
        let count = 0;

        evt.currentTarget.classList.add('hidesBtn');
        loadingBtnIsHidden = true;

        (window as any).webim = { accountName: 'tektorgru', domain: 'tektorgru.webim.ru', location: 'departments' };

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://tektorgru.webim.ru/js/button.js';
        document.head.appendChild(script);

        const btnClick = () => {
            const webim_button = document.querySelector('a.webim_button.webim-button-slider') as HTMLElement;

            // webim_button && (webim_button.style.transition = "0s");
            // webim_button && (webim_button.style.opacity = "0");

            if (webim_button) {
                webim_button.click();
                clearInterval(interval);
            }

            count++;
            if (count >= MAX_COUNT) clearInterval(interval);
        };

        const interval = setInterval(btnClick, delay);
    };

    useEffect(() => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                loadingBtnRef.current && (loadingBtnRef.current.style.opacity = '1');
            });
        });
    }, []);

    useEffect(() => {
        if (loadingBtnIsHidden && loadingBtnRef.current) {
            loadingBtnRef.current.style.transition = 'unset';
            loadingBtnRef.current.classList.add('hidesBtn');
        }
    }, [asPath, loadingBtnIsHidden]);

    return (
        <Container ref={loadingBtnRef} onClick={clickHandler}>
            <LoadingBtn>
                <DecorLine />
            </LoadingBtn>
        </Container>
    );
};
