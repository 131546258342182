import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const _44FZ: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/44-fz/service': SAMPLES.SERVICE_BLOCKS_INTERACTION_TAGS, //работает
    '/44-fz/service/e-signature': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/eruz': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/consultdoc': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/icwe': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/accompany-tenders': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/edo': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/special-account': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/credit-on-contract': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/bg': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/credit': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/44-fz/service/credit-on-procurement': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/44-fz/suppliers/help': SAMPLES.CLIENT_CENTER_FORMS_SAMPLES,
    '/44-fz/customers/help': SAMPLES.CLIENT_CENTER_FORMS_SAMPLES
};
