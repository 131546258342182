import styled from 'styled-components';
import {
    color,
    font,
    sideOffsets,
    offset,
    mediaBreakpointUp,
    mediaBreakpointDown,
    allBreakpointValue,
    hover,
    vw,
    buttonStyle
} from '@/style/mixins';

import { Container as FooterTechSupport } from '@/components/blocks/FooterTechSupport/FooterTechSupport.styled';
import { Container as MenuList } from '@/components/blocks/MenuList/MenuList.styled';
import { Container as Spoiler } from '@/components/blocks/Spoiler/Spoiler.styled';
import { Container as FooterContacts } from '@/components/blocks/FooterContacts/FooterContacts.styled';
import variables from '@/style/variables';

export const Container = styled.footer`
    position: relative;
    overflow: hidden;
    ${sideOffsets()};
    ${allBreakpointValue('padding-top', 80, 60, 54, 60, 40)};
    ${allBreakpointValue('padding-bottom', 35, 40, 26, 24, 23)};
    color: ${color('white')};
    background-color: ${color('black2')};

    ${FooterTechSupport} {
        ${allBreakpointValue('margin-left', 155 + 287, 104 + 228, 82 + 213)};
    }

    ${mediaBreakpointUp('fhd')} {
        padding-right: ${vw(160)};
        padding-left: ${vw(160)};
    }
`;

export const FooterTop = styled.div<{ heightMenu: number }>`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    margin-top: 17px;

    ${mediaBreakpointUp('md')} {
        margin-top: 24px;
    }

    ${mediaBreakpointUp('xl')} {
        margin-top: 0;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 1px;
        background-color: ${color('white', 0.1)};
        left: ${vw(-variables.offset.mobile, 375)};
        right: ${vw(-variables.offset.mobile, 375)};

        ${mediaBreakpointUp('md')} {
            left: ${vw(-variables.offset.tablet, 768)};
            right: ${vw(-variables.offset.tablet, 768)};
        }

        ${mediaBreakpointUp('xl')} {
            display: none;
        }
    }

    ${FooterContacts} {
        order: 1;
        width: 42.71%;
        flex-shrink: 0;
        ${allBreakpointValue('width', 155 + 287, 104 + 228, 82 + 213)};
        ${allBreakpointValue('padding-top', 71, 60, 54, 40)};

        ${mediaBreakpointDown('md')} {
            width: 100%;
            padding-bottom: 40px;
            padding-top: 40px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                width: 100vw;
                height: 1px;
                background-color: ${color('white', 0.1)};
                transform: translateX(-50%);
            }
        }

        ${mediaBreakpointUp('md')} {
            border-left: thin solid ${color('white', 0.1)};
            padding-left: ${vw(40, 'md')};
            padding-bottom: ${vw(60, 'md')};
        }

        ${mediaBreakpointUp('xl')} {
            border-left: none;
            padding-bottom: 0;
            padding-left: 0;
            order: 0;
        }
    }
`;

export const FooterLogo = styled.a<{ router?: boolean }>`
    display: block;
    z-index: 1;

    ${allBreakpointValue('width', 257, 192, 171, 221, 221)};
    ${allBreakpointValue('height', 40, 30, 27, 35, 35)};

    cursor: ${({ router }) => (router ? 'pointer' : 'default')};

    ${mediaBreakpointUp('xl')} {
        position: absolute;
    }

    svg {
        display: inline-block;
        width: auto;
        height: 100%;
        fill: currentColor;

        .logo-r_t {
            stroke: ${color('white')};
            fill: ${color('white')};
        }
    }
`;

export const FooterMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${allBreakpointValue('margin-bottom', -45, -45, -40, -16)};

    padding-top: 40px;
    padding-bottom: 34px;

    ${mediaBreakpointUp('md')} {
        width: 57.29%;
        padding-top: ${vw(40, 'md')};
        padding-bottom: ${vw(60, 'md')};
    }

    ${mediaBreakpointUp('xl')} {
        width: calc(100% - ${vw(82 + 213, 'xl')});
        padding-top: 0;
        padding-bottom: 0;

        flex-wrap: wrap;
        flex-direction: row;
    }

    ${mediaBreakpointUp('xxl')} {
        width: calc(100% - ${vw(104 + 228, 'xxl')});
    }

    ${mediaBreakpointUp('fhd')} {
        width: calc(100% - ${vw(155 + 287)});
    }
`;

export const FooterMenuItem = styled.div`
    position: relative;
    ${allBreakpointValue('margin-bottom', 45, 45, 40, 16, 24)};

    ${mediaBreakpointUp('xl')} {
        width: calc(100% / 3);
    }

    p {
        ${font('text1-b')};

        & + ul {
            ${offset('s', 'margin-top')};
        }

        a {
            transition: opacity 0.3s ease-in-out;
            ${hover(` opacity: 0.6`)};
        }
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;

        & > li > ul {
            margin-top: 12px;
            ${font('text3-l')};

            padding-left: 20px;
        }

        span {
            opacity: 0.5;
        }
    }

    li {
        list-style: none;
        ${font('text3-l')};

        &:not(:last-child) {
            ${offset('xxs', 'margin-bottom')};
        }

        a {
            opacity: 0.5;
            transition: opacity 0.3s ease-in-out;
            ${hover(` opacity: 1`)};
        }
    }

    ${MenuList} {
        ${Spoiler} {
            span {
                opacity: 0.5;
                transition: opacity 0.3s ease-in-out;
                ${hover(` opacity: 1`)};
            }

            ul {
                margin-top: 12px;
                ${font('text3-l')};

                padding-left: 20px;
            }
        }
    }
`;

export const FooterPurchases = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${allBreakpointValue('margin-bottom', 56, 45, 40, 16)};
`;

export const FooterPurchasesLink = styled.a`
    display: inline-block;
    ${font('text1-b')};
    ${allBreakpointValue('margin-bottom', 22, 18, 16, 16, 28)};
`;

export const FooterPurchasesBtn = styled.a`
    ${buttonStyle(true, true)};
    ${font('text2-l')};
    border: 1px solid ${color('white')};
    color: ${color('white')};
    display: flex;
    align-self: flex-start;

    ${hover(`
        border-color: ${color('white')};
        
        span {
            opacity: 0.5;
        }
    `)};

    ${mediaBreakpointDown('md')} {
        width: 100%;
        justify-content: center;
    }

    ${mediaBreakpointUp('fhd')} {
        padding: ${vw(9)} ${vw(27)} ${vw(11)};
    }
`;

export const FooterBottom = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    ${allBreakpointValue('padding-top', 22, 18, 16, 24, 14)};

    &::before {
        content: '';
        position: absolute;
        top: 0;
        height: 1px;
        background-color: ${color('white', 0.1)};

        left: 0;
        right: 0;
    }

    p {
        margin-right: auto;
        ${font('text3-l')};
        opacity: 0.8;

        ${mediaBreakpointDown('md')} {
            max-width: 160px;
        }
    }

    a {
        align-items: flex-end;
        display: flex;
        transition: opacity 0.3s ease-in-out;

        ${hover(` opacity: 0.6`)};

        svg {
            ${allBreakpointValue('width', 110, 89, 89)};
            fill: ${color('white')};
        }
    }
`;
