import { WebvisorStyle, setDOMStyles } from '@/helpers/setupDOMStyles/setDOMStyles';
import Stylis from '@emotion/stylis';
import { useEffect } from 'react';

export const useDOMStyles = () => {
    useEffect(() => {
        const observerOptions = { childList: true, attributes: true, subtree: true };

        const styleContainer = document.querySelectorAll('[data-styled]')[0] as HTMLStyleElement;

        const webvisorStyle = document.createElement('style') as WebvisorStyle;

        webvisorStyle.id = 'webvisorStyle';

        document.head.insertAdjacentElement('afterbegin', webvisorStyle);

        const stylis = new Stylis();

        const observer = new MutationObserver(() => setDOMStyles(webvisorStyle, styleContainer, stylis));

        observer.observe(document.body, observerOptions);

        return () => observer.disconnect();
    }, []);
};
