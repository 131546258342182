import { getWindowScrollY } from '@/helpers/getWindowScrollY';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Router } from 'next/router';
import { useRef, useEffect } from 'react';

interface HandlerOptions {
    shallow: boolean;
}

/**
 * @description Хук сохраняет значение scrollY предыдущей cтраницы
 * и, при возвращении на предыдущую страницу,
 * прокручивает ее до сохраненной позиции scrollY
 */

export const usePrevPageScrollY = (router: Router) => {
    const prevPageURLRef = useRef('');
    const prevPageScrollYRef = useRef(0);
    const nextPageScrollYRef = useRef(0);

    useEffect(() => {
        const handleStart = (url: string, { shallow }: HandlerOptions) => {
            if (shallow) return;

            const asPath = router.asPath;
            const isPrevPage = prevPageURLRef.current === url;

            disableBodyScroll(document.body);
            prevPageURLRef.current = router.locale === 'en' ? (asPath === '/' ? '/en' : `/en${asPath}`) : asPath;

            if (isPrevPage) {
                nextPageScrollYRef.current = prevPageScrollYRef.current;
                prevPageScrollYRef.current = getWindowScrollY();
            } else {
                prevPageScrollYRef.current = getWindowScrollY();
                nextPageScrollYRef.current = 0;
            }

            // При переходе со страницы getStaticProps на предыдущую страницу getServerSideProps,
            // текущая страница, до перехода, скроллится до позиции предыдущей страницы,
            // если не задан scrollRestoration = 'manual'.
            // setTimeout, расположенный ниже, решает эту проблему,
            // но, при переходах между страницами, появляются проблемы визуального характера.
        };

        const handleComplete = (_: string, { shallow }: HandlerOptions) => {
            if (shallow) return;

            setTimeout(() => {
                enableBodyScroll(document.body);

                if (window.location?.hash === '') {
                    window.scrollTo(0, nextPageScrollYRef.current);
                }
            }, 700);
        };

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
        };
    }, []);
};
