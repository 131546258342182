import { mediaBreakpointUp, vw, smoothValue, color, mediaBreakpointDown, hover } from '@/style/mixins';
import styled from 'styled-components';

export const Container = styled.div<{ isOpenLang?: boolean }>`
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    z-index: 1;
    transition: color 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        order: 3;
        margin-left: ${vw(40, 768)};
    }

    ${mediaBreakpointUp('xl')} {
        order: 0;
        width: ${smoothValue(42, 49, 1280, 1440, 'w')};
        margin-left: ${smoothValue(35, 36, 1280, 1440, 'w')};
        color: ${({ isOpenLang }) => (isOpenLang ? color('black') : 'currentColor')};

        &:hover {
            color: ${color('black')};

            & > div {
                opacity: 1;
                visibility: visible;
            }

            span,
            a {
                outline-color: rgba(0, 0, 0, 0.1);
                background-color: ${color('white')};
            }
        }
    }

    ${mediaBreakpointUp('fhd')} {
        width: ${vw(60)};
        margin-left: ${vw(50)};
    }

    span,
    a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        line-height: 1;

        ${mediaBreakpointUp('xl')} {
            height: ${smoothValue(37, 43, 1280, 1440, 'w')};
            padding: ${smoothValue(12, 14, 1280, 1440, 'w')};

            transition: outline-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

            outline: thin solid ${({ isOpenLang }) => (isOpenLang ? 'rgba(0, 0, 0, 0.1)' : 'transparent')};
            background-color: ${({ isOpenLang }) => (isOpenLang ? color('white') : 'transparent')};
        }

        ${mediaBreakpointUp('fhd')} {
            height: ${vw(52)};
            padding: ${vw(16)};
        }
    }

    a {
        ${mediaBreakpointUp('xl')} {
            &:hover {
                color: ${color('blue')};
            }
        }
    }

    & > div {
        ${mediaBreakpointDown('xl')} {
            margin-left: 16px;
            order: 2;
            opacity: 0.5;
            ${hover(` opacity: 1`)};
        }

        ${mediaBreakpointUp('xl')} {
            position: absolute;
            left: 0;
            right: 0;

            top: ${smoothValue(37, 43, 1280, 1440, 'w')};

            transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

            opacity: ${({ isOpenLang }) => (isOpenLang ? 1 : 0)};
            visibility: ${({ isOpenLang }) => (isOpenLang ? 'visible' : 'hidden')};
        }

        ${mediaBreakpointUp('fhd')} {
            top: ${vw(52)};
        }
    }
`;
