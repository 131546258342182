import styled from 'styled-components';
import { color, font, mediaBreakpointUp } from '@/style/mixins';
import { Colors } from '@/style/colors';

export interface TagContainerProps {
    big?: boolean;
    gray?: boolean;
    black?: boolean;
    backgroundColor?: Colors;
    textColor?: Colors;
}

export const Container = styled.div<TagContainerProps>`
    position: relative;
    border-radius: 30px;
    background: ${({ gray, black }) => (gray || black ? (black ? color('black') : color('gray')) : color('white'))};

    ${({ big }) => (big ? font('text2-l') : font('text3-l'))};

    ${({ big }) =>
        big
            ? `
        padding: 5px 10px 6px;
        border: 2px solid transparent;
        
        ${mediaBreakpointUp('xl')} {
            padding: 7px 8px 9px;
            
            &:focus {
                border-color: ${color('blue3')};
            }
            
            &:active {
                background: ${color('blue')};
            }
            &:hover {
                color: ${color('black', 0.5)};
            }
        }
        ${mediaBreakpointUp('fhd')} {
            padding: 7px 12px 9px;
        }
    `
            : `
        color: ${color('black')};
        padding: 6px 8px 7px;
        
        ${mediaBreakpointUp('fhd')} {
            padding: 6px 12px 8px;
        }
    `};

    color: ${({ black }) => black && color('white')};

    ${({ backgroundColor }) => backgroundColor && `background-color:${color(backgroundColor)}`};
    ${({ textColor }) => textColor && `color:${color(textColor)}`};
`;
