import { combineReducers } from '@reduxjs/toolkit';
import preloaderSlice from '@/redux/preloader/preloaderSlice';
import pageTransitionSlice from '@/redux/pageTransition/pageTransitionSlice';
import sandwichSlice from '@/redux/sandwich/sandwichSlice';
import modalSlice from '@/redux/modal/modalSlice';
import bannerSlice from '@/redux/banners/bannersSlice';
import searchProceduresSlice from '@/redux/searchProcedures/searchProceduresSlice';
import listingProceduresSlice from '@/redux/listingProcedures/listingProceduresSlice';
import listingPartnersSlice from './listingPartners/listingPartnersSlice';
import listingQualificationSlice from '@/redux/listingQualificationOrgs/listingQualificationOrgsSlice';
import listingLotsSlice from '@/redux/listingLots/listingLotsSlice';
import rosneftNewsCheckSlice from './rosneftNewsCheck/rosneftNewsCheckSlice';
import articleSlugSlice from './articleSlugs/articleSlugSlice';
import importantMessageSlice from './importantMessage/importantMessageSlice';
import ourCustomersSlice from './ourCustomers/ourCustomersSlice';
import footerSlice from './footer/footerSlice';

const rootReducer = combineReducers({
    preloader: preloaderSlice,
    pageTransition: pageTransitionSlice,
    rosneftNewsCheck: rosneftNewsCheckSlice,
    sandwich: sandwichSlice,
    modal: modalSlice,
    banners: bannerSlice,
    searchProcedures: searchProceduresSlice,
    listingProcedures: listingProceduresSlice,
    listingPartners: listingPartnersSlice,
    listingLots: listingLotsSlice,
    listingQualificationOrgs: listingQualificationSlice,
    articleSlugs: articleSlugSlice,
    importantMessage: importantMessageSlice,
    ourCustomers: ourCustomersSlice,
    footer: footerSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
