import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import {
    Container,
    FooterTop,
    FooterLogo,
    FooterMenu,
    FooterMenuItem,
    FooterBottom,
    FooterPurchases,
    FooterPurchasesBtn,
    FooterPurchasesLink
} from './Footer.styled';
import useTranslation from '@/hooks/useTranslation';
import { useRouter } from 'next/router';
import { FooterI, MenuILink } from '@/interfaces';
import Logo from '@/icons/logo.svg';
import LogoEn from '@/icons/logo-en.svg';
import Only from '@/icons/made-only-footer.svg';
import FooterTechSupport from '@/components/blocks/FooterTechSupport/FooterTechSupport';
import FooterContacts from '@/components/blocks/FooterContacts/FooterContacts';
import PotentiallyLink from '@/components/common/PotentiallyLink/PotentiallyLink';
import MenuList from '@/components/blocks/MenuList/MenuList';
import { sendMetrik } from '@/helpers';

const Spoiler = dynamic(() => import('@/components/blocks/Spoiler/Spoiler'));

const ModalWrapper = dynamic(() => import('../../common/ModalWrapper/ModalWrapper'));
const CallBackForm = dynamic(() => import('../../common/CallBackForm/CallBackForm'));
const AskQuestion = dynamic(() => import('../../common/AskQuestion/AskQuestion'));
const ServicesForm = dynamic(() => import('../../blocks/ServicesForm/ServicesForm'));
const ServicesFormWithOrgName = dynamic(() => import('../../blocks/ServicesFormWithOrgName/ServicesFormWithOrgName'));
const NDSForm = dynamic(() => import('../../blocks/NDSForm/NDSForm'));

import ResizeObserver from 'resize-observer-polyfill';
import Link from 'next/link';
import useIsMatches from '@/hooks/useIsMatches';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { setIsFooterVisible } from '@/redux/footer/footerSlice';
import { useServicePage } from '@/hooks/useServicePage';

interface FooterProps {
    propsFooterForm?: string;
    modalTitle?: string;
    modalID?: string;
}

const Footer: React.FC<FooterProps> = ({ propsFooterForm, modalTitle, modalID }) => {
    const { t } = useTranslation('common');
    const [menu] = useState<MenuILink[] | []>(() => t('menu', { returnObjects: true }));
    const [footer] = useState<FooterI>(() => t('footer', { returnObjects: true }));
    const [heightMenu, setHeightMenu] = useState(0);
    const [currentYear] = useState<number>(() => new Date().getFullYear());
    const menuRef = useRef<HTMLDivElement | null>(null);
    const router = useRouter();
    const isMobile = useIsMatches('xl');
    const servicePage = useServicePage();
    const { ref, inView } = useInView();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsFooterVisible(inView));
    }, [inView]);

    useEffect(() => {
        let resizeObserver: ResizeObserver;

        const resizeHandler = () => {
            if (menuRef.current) {
                setHeightMenu(menuRef.current.clientHeight);
            }
        };
        if (menuRef.current) {
            resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver?.observe(document.documentElement);
            resizeHandler();
        }

        return () => resizeObserver?.disconnect();
    }, [menuRef]);

    const handleRouter = (e: SyntheticEvent) => {
        e.preventDefault();
        if (router.pathname === '/') return;

        router.push('/');
    };

    return (
        <Container ref={ref}>
            <FooterLogo onClick={handleRouter} router={router.pathname !== '/'} as={router.pathname !== '/' ? 'a' : 'span'}>
                {router.locale === 'ru' ? <Logo /> : <LogoEn />}
            </FooterLogo>

            <FooterTop heightMenu={heightMenu}>
                <FooterContacts {...footer} />

                <FooterMenu ref={menuRef}>
                    {isMobile
                        ? menu?.map(
                              (item, index) =>
                                  item.subLinks && (
                                      <FooterMenuItem key={index}>
                                          <Spoiler key={index} header={<span> {item.text} </span>} headerBtn={false}>
                                              <ul>
                                                  {item.subLinks.map((innerItem, i) => (
                                                      <li key={i}>
                                                          {innerItem.subLinks ? (
                                                              <>
                                                                  <span> {innerItem.text} </span>
                                                                  <ul>
                                                                      {innerItem.subLinks.map(({ href, text }, i) => (
                                                                          <li key={i}>{PotentiallyLink({ href, text, i })}</li>
                                                                      ))}
                                                                  </ul>
                                                              </>
                                                          ) : (
                                                              PotentiallyLink(innerItem)
                                                          )}
                                                      </li>
                                                  ))}
                                              </ul>
                                          </Spoiler>
                                      </FooterMenuItem>
                                  )
                          )
                        : menu?.map(
                              (item, index) =>
                                  item.subLinks && (
                                      <FooterMenuItem key={index}>
                                          <p> {PotentiallyLink(item)} </p>
                                          {item.subLinks && MenuList(item.subLinks)}
                                      </FooterMenuItem>
                                  )
                          )}

                    <FooterPurchases>
                        {!!footer?.purchases &&
                            footer?.purchases.map(({ href, text, type }, i) => {
                                return router.pathname !== href ? (
                                    <Link href={href} passHref key={`${href}${i}`} prefetch={false}>
                                        {type ? (
                                            <FooterPurchasesBtn
                                                onClick={() => {
                                                    sendMetrik('footer-click-subcribe', 'footer', {});
                                                }}
                                            >
                                                {text}
                                            </FooterPurchasesBtn>
                                        ) : (
                                            <FooterPurchasesLink>
                                                <span>{text}</span>
                                            </FooterPurchasesLink>
                                        )}
                                    </Link>
                                ) : (
                                    <>
                                        {type ? (
                                            <FooterPurchasesBtn
                                                onClick={() => {
                                                    sendMetrik('footer-click-subcribe', 'footer', {});
                                                }}
                                                as={'span'}
                                            >
                                                {text}
                                            </FooterPurchasesBtn>
                                        ) : (
                                            <FooterPurchasesLink as={'span'}>{text}</FooterPurchasesLink>
                                        )}
                                    </>
                                );
                            })}
                    </FooterPurchases>
                </FooterMenu>
            </FooterTop>

            <FooterTechSupport {...footer.techSupport} />

            <FooterBottom>
                {footer.label && <p dangerouslySetInnerHTML={{ __html: `&copy; ${currentYear} ${footer.label}` }} />}
                <a href={'https://onlydigital.ru'} target={'_blank'} rel={'noreferrer'}>
                    <Only />
                </a>
            </FooterBottom>

            <ModalWrapper id={'callBackForm'} title={t('callBackForm.title')} description={t('callBackForm.description')}>
                <CallBackForm formId={'help'} />
            </ModalWrapper>

            <ModalWrapper id={'askQuestionForm'} title={t('askQuestionForm.title')} description={t('askQuestionForm.description')}>
                <AskQuestion formId={'help'} />
            </ModalWrapper>

            <ModalWrapper
                description={t('marketCenter.description')}
                id={'ApplicationForm3MarketCentre'}
                title={t('ServicesForm.title2', { returnObjects: true })}
            >
                <ServicesForm
                    {...t('ServicesForm', { returnObjects: true })}
                    formId={'ApplicationForm3MarketCentre'}
                    landing_id={propsFooterForm}
                />
            </ModalWrapper>

            <ModalWrapper
                description={t('manageDogovors.description')}
                id={'ApplicationForm3ManageDogovor'}
                title={t('ServicesForm.title2', { returnObjects: true })}
            >
                <ServicesForm
                    {...t('ServicesForm', { returnObjects: true })}
                    formId={'ApplicationForm3ManageDogovor'}
                    landing_id={propsFooterForm}
                />
            </ModalWrapper>

            <ModalWrapper
                description={t('nsi.description')}
                id={'ApplicationForm3NSI'}
                title={t('ServicesForm.title2', { returnObjects: true })}
            >
                <ServicesForm {...t('ServicesForm', { returnObjects: true })} formId={'ApplicationForm3NSI'} landing_id={propsFooterForm} />
            </ModalWrapper>

            <ModalWrapper
                description={modalTitle ? modalTitle : servicePage.titles.title}
                id={'ApplicationForm3'}
                title={t('ServicesForm.title2', { returnObjects: true })}
            >
                <ServicesForm
                    {...t('ServicesForm', { returnObjects: true })}
                    formId={modalID ?? 'callback_loyalty'}
                    landing_id={propsFooterForm}
                />
            </ModalWrapper>
            <ModalWrapper
                description={modalTitle ? modalTitle : servicePage.titles.title}
                id={'ServicesFormWithTitle'}
                title={t('ServicesForm.title2', { returnObjects: true })}
            >
                <ServicesFormWithOrgName
                    {...t('ServicesForm', { returnObjects: true })}
                    formId={modalID ?? 'callback_loyalty'}
                    landing_id={propsFooterForm}
                />
            </ModalWrapper>
            <ModalWrapper {...t('ndsModal', { returnObjects: true })} id={'NDSForm'}>
                <NDSForm formId={'e-signature_non-residents'} />
            </ModalWrapper>
        </Container>
    );
};

export default Footer;
