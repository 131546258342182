import { useEffect, useState } from 'react';

/**
 * @description Определение браузера Internet Explorer 11
 */

function useIsIE11() {
    const [isIE11, setIsIE11] = useState(false);

    useEffect(() => {
        setIsIE11(typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.DOCUMENT_NODE);
    }, []);

    return isIE11;
}
export default useIsIE11;
