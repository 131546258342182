import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: boolean = false;

const sandwichSlice = createSlice({
    name: 'sandwich',
    initialState,
    reducers: {
        setSandwich(_state, action: PayloadAction<boolean>) {
            return action.payload;
        },
        switchSandwich(_state) {
            return !_state;
        }
    }
});

export const { setSandwich, switchSandwich } = sandwichSlice.actions;

export default sandwichSlice.reducer;
