import { font, color, mediaBreakpointUp, vw, hover } from '@/style/mixins';
import styled, { css } from 'styled-components';
import { ButtonProps } from './Button';
import CircularAnimation from '../CircularAnimation/CircularAnimation';

export const Container = styled.button<Required<Pick<ButtonProps, 'haveBg' | 'small' | 'bgColor' | 'textColor'>>>`
    position: relative;
    display: inline-block;
    padding: ${({ small }) => (small ? '10px 16px' : '14px 25px')};
    border: 1px solid ${({ haveBg, bgColor }) => (haveBg ? `${color(bgColor)}` : 'currentColor')};
    border-radius: 8px;
    ${font('button')};
    color: ${({ textColor }) => color(textColor)};
    background-color: ${({ haveBg, bgColor }) => (haveBg ? `${color(bgColor)}` : 'transparent')};
    transition: 0.3s ease-in-out;
    cursor: pointer;

    ${mediaBreakpointUp('md')} {
        padding: ${({ small }) => (small ? `${vw(10, 'md')} ${vw(16, 'md')}` : `${vw(14, 'md')} ${vw(24, 'md')}`)};
    }

    ${mediaBreakpointUp('xl')} {
        padding: ${({ small }) => (small ? `${vw(8.5, 'xl')} ${vw(20, 'xl')}` : `${vw(12, 'xl')} ${vw(26, 'xl')}`)};
    }

    ${mediaBreakpointUp('xxl')} {
        padding: ${({ small }) => (small ? `${vw(9.5, 'xxl')} ${vw(22, 'xxl')}` : `${vw(14, 'xxl')} ${vw(30, 'xxl')}`)};
    }

    ${mediaBreakpointUp('fhd')} {
        padding: ${({ small }) => (small ? `${vw(13)} ${vw(30)}` : `${vw(19)} ${vw(40)}`)};
    }

    ${hover('opacity: .5')};

    &:disabled {
        opacity: 0.2;
        cursor: default;
        background-clip: padding-box;

        ${hover('opacity: 0.2')};
    }
`;

export const Text = styled.span<Pick<ButtonProps, 'isLoading'>>`
    ${({ isLoading }) =>
        isLoading &&
        css`
            opacity: 0;
        `}
`;

export const Spinner = styled(CircularAnimation)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .spinner .path {
        stroke: currentColor;
    }
`;
