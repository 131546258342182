import { IMetrikaNewSample } from '@/interfaces';
import SAMPLES from '@/metriks/samples';

export const Services: { [key: string]: { [key: string]: IMetrikaNewSample } } = {
    '/services/customers': SAMPLES.SERVICE_BLOCKS_INTERACTION_TAGS,
    '/services/service-property-buyers': SAMPLES.SERVICE_BLOCKS_INTERACTION_TAGS,
    '/services/customers-223-fz': SAMPLES.SERVICE_BLOCKS_INTERACTION_TAGS, //работает
    '/services/customers-223-fz/nmc': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/customers-223-fz/digital_helper': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/customers-223-fz/outsourcing': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/customers/nmc': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/customers/digital_helper': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/customers/outsourcing': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/e-signature': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/eruz': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/fast-accreditation': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/134': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/consultdoc': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/icwe': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/accompany-tenders': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/placement-production': SAMPLES.SERVICES_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/bg': { ...SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES, ...SAMPLES.BANK_GUARANTEE_FORM_SAMPLES },
    '/services/service-suppliers/credit-on-procurement': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/special-account': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/credit-on-contract': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/credit': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/factoring': SAMPLES.FINANCE_BLOCK_FORMS_SAMPLES,
    '/services/service-suppliers/edo': SAMPLES.SERVICE_BLOCK_FORMS_SAMPLES
};
