import { useMemo } from 'react';
import { Container } from './HeaderLang.styled';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { sendMetrik } from '@/helpers';
import { ArticleSlugState } from '@/redux/articleSlugs/articleSlugSlice';
import { RootState } from '@/redux/rootReducer';
import { useSelector } from 'react-redux';

const isNonexistentPath = (href: string) => {
    const nonexistentPaths = ['/education/events/', '/education/courses/'];

    return nonexistentPaths.includes(href);
};

const isArticlePage = (pathArr: string[]) => {
    const parentPage = pathArr[pathArr.length - 2];

    return (
        parentPage === 'news-press' ||
        parentPage === 'news-media' ||
        parentPage === 'news-important' ||
        parentPage === 'news-announce' ||
        parentPage === 'expert-articles' ||
        parentPage === 'expert-articles-supliers' ||
        parentPage === 'events' ||
        parentPage === 'courses'
    );
};

const getArticlePath = (pathArr: string[], articleSlugs: ArticleSlugState, lang: string) => {
    const newPathArr = [...pathArr];

    newPathArr[newPathArr.length - 1] = articleSlugs[lang as 'ru' | 'en'];

    return newPathArr.join('/');
};

export const HeaderLang = () => {
    const { asPath, locales, locale } = useRouter();
    const articleSlugs = useSelector((state: RootState) => state.articleSlugs);

    const pathArr = useMemo(() => asPath.split('/'), [asPath]);

    return (
        <Container>
            <span>{locale}</span>

            {locales &&
                locales
                    .filter((lang) => lang !== locale)
                    .map((lang) => {
                        const href = isArticlePage(pathArr) ? getArticlePath(pathArr, articleSlugs, lang) : asPath;

                        if (isNonexistentPath(href)) return null;

                        return (
                            <div key={lang}>
                                <Link href={href} prefetch={false} locale={lang}>
                                    <a onClick={() => sendMetrik('menu-change-language', 'menu', { 'choose-language': lang })}>{lang}</a>
                                </Link>
                            </div>
                        );
                    })}
        </Container>
    );
};
