import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pageTransitionStateTypes } from './types';

const initialState: pageTransitionStateTypes = {
    done: false,
    isInitialRouterPage: true
};

const pageTransitionSlice = createSlice({
    name: 'pageTransition',
    initialState,
    reducers: {
        setDone(state, action: PayloadAction<boolean>) {
            state.done = action.payload;
        },
        setIsInitialRouterPage(state, action: PayloadAction<boolean>) {
            state.isInitialRouterPage = action.payload;
        }
    }
});

export const { setDone, setIsInitialRouterPage } = pageTransitionSlice.actions;

export default pageTransitionSlice.reducer;
