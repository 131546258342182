import { useEffect } from 'react';
import { clearFromNBSP, sendMetrik } from '@/helpers';

const useBannerMetrics = (inView: boolean, sideBanners: any) => {
    useEffect(() => {
        if (inView && sideBanners) {
            if (!!sideBanners.length) {
                sideBanners.forEach((item: any) =>
                    sendMetrik('banners-impressions', 'banners', { 'banner-id-impression': clearFromNBSP(item.title) }, 'impressions')
                );
            } else {
                sendMetrik('banners-impressions', 'banners', { 'banner-id-impression': clearFromNBSP(sideBanners.title) }, 'impressions');
            }
        }
    }, [inView]);
};

export default useBannerMetrics;
