import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IPartners } from '@/interfaces';
import { getPartnersFetch } from '@/lib/api';
import { AxiosResponse } from 'axios';

export type ListingPartnersState = {
    data: IPartners[];
    total: number;
    totalPages: number;
    currentPage: number;
    limitPage: number;
    status: string;
    statusLoadMore: string;
    loadMore: boolean;
    extraFilters: object;
    extraUrlQuery: object;
};

export const fetchPartners = createAsyncThunk('listingPartners/fetchPartners', async (params: any) => {
    const { data }: AxiosResponse = await getPartnersFetch(params);
    return data;
});

export const fetchMorePartners = createAsyncThunk('listingPartners/fetchMorePartners', async (params: any) => {
    const { data }: AxiosResponse = await getPartnersFetch(params);
    return data;
});

export const initialState: ListingPartnersState = {
    data: [],
    total: 0,
    totalPages: 1,
    currentPage: 1,
    limitPage: 10,
    status: 'loading',
    statusLoadMore: 'success',
    loadMore: false,
    extraFilters: {},
    extraUrlQuery: {}
};

const listingPartnersSlice = createSlice({
    name: 'listingPartners',
    initialState,
    reducers: {
        setCurrentPage(state, action) {
            state.currentPage = action.payload.currentPage;
            state.loadMore = action.payload.loadMore;
        },
        setFilters(state, action) {
            state.currentPage = !action.payload.page ? initialState.currentPage : Number(action.payload.page);
        },
        setExtraFilters(state, action) {
            state.extraFilters = action.payload;
            state.currentPage = initialState.currentPage;
        },
        setExtraUrlQuery(state, action) {
            state.extraUrlQuery = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPartners.pending, (state) => {
            state.status = 'loading';
            state.data = [];
        });
        builder.addCase(fetchPartners.fulfilled, (state, action) => {
            if (action.payload.error) {
                state.status = 'error';
                state.data = [];
                state.total = initialState.total;
                state.totalPages = initialState.totalPages;
            } else {
                state.data = action.payload.data;
                state.total = action.payload.total;
                state.totalPages = action.payload.totalPages;
                state.status = 'success';
            }
        });
        builder.addCase(fetchPartners.rejected, (state) => {
            state.status = 'error';
            state.data = [];
        });
        builder.addCase(fetchMorePartners.pending, (state) => {
            state.statusLoadMore = 'loading';
            state.loadMore = false;
        });
        builder.addCase(fetchMorePartners.fulfilled, (state, action) => {
            state.data = [...state.data, ...action.payload.data];
            state.total = action.payload.total;
            state.totalPages = action.payload.totalPages;
            state.statusLoadMore = 'success';
        });
        builder.addCase(fetchMorePartners.rejected, (state) => {
            state.statusLoadMore = 'error';
        });
    }
});

export const { setCurrentPage, setFilters, setExtraFilters, setExtraUrlQuery } = listingPartnersSlice.actions;

export default listingPartnersSlice.reducer;
