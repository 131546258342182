import { createGlobalStyle, keyframes } from 'styled-components';
import { color, fontFamily, mediaBreakpointDown, scrollbar } from './mixins';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        ${scrollbar};
    }

    html {
        ${fontFamily('NunitoSansSemiBold')};
        scrollbar-gutter: stable;
        color: ${color('black')};

        --scrollbar-width: 11px;
    }

    body {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        padding: 0;
        margin: 0;
        overscroll-behavior: none;
        overflow-x: hidden;
        overflow-y: hidden;
        text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;

        &.ready{
            overflow-y: auto;
        }
    }

    h1, h2, h3, h4, h5, span, a, p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        text-decoration: inherit;
        padding: 0;
        margin: 0;
        word-break: break-word;
        word-wrap: break-word;
    }

    a {
        text-decoration: none;
        color: inherit;
        outline: none;
    }

    button {
        border: transparent;
        outline: transparent;

        &, &:active,
        &:focus {
            outline: none;
        }
    }

    .noWrap {
        white-space: nowrap;
    }

    #__next {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .ReactModal__Overlay {
        opacity: 0;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
    }

    .ReactModal__Body--open {
        overflow: hidden !important;
        touch-action: none;
        -ms-touch-action: none;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .webim_button.webim_button.webim-button-slider  {
        display: block !important;
        z-index: 500 !important;
        top: auto !important;
        bottom: 5px !important;
        left: calc(100vw - 65px - var(--scrollbar-width) - 7px) !important;
        width: 65px !important;
        height: 65px;
        background: url('/images/webim-button.png') center no-repeat;
        background-size: 80px;
        animation: ${fadeIn} 0.7s;

        ${mediaBreakpointDown('xl')} {
            left: unset !important;
            right: 7px !important;
        }

        img {
            display: none;
        }
    }

    .fade-enter {
        opacity: 0;
    }

    .fade-exit {
        opacity: 1;
    }

    .fade-enter-active {
        opacity: 1;
    }

    .fade-exit-active {
        opacity: 0;
    }

    .fade-enter-active {
        transition: opacity 0.5s 0.3s;
    }

    .fade-exit-active {
        transition: opacity 0.3s;
    }

    .__react_component_tooltip.evaluation__tooltip {
        &.show {
            opacity: 1 !important;
        }

        &::after {
            width: 0.5em !important;
            height: 0.5em !important;
            background-color: ${color('white')} !important;
        }

        &.place-top {
            margin-top: -0.6em;

            &::after {
                bottom: -0.3em;
                margin-left: -0.3em;
            }
        }

        &.place-right {
            margin-left: 0.6em;

            &::after {
                left: -0.3em;
                margin-top: -0.3em;
            }
        }

        &.place-bottom {
            margin-top: 0.6em;

            &::after {
                top: -0.3em;
                margin-left: -0.3em;
            }
        }

        &.place-left {
            margin-left: -0.6em;

            &::after {
                right: -0.3em;
                margin-top: -0.3em;
            }
        }

    }

    @media print {

        .sidebar {
            display: none;
        }
    }

    .swiper-wrapper {
        -webkit-display: flex;
        -moz-display: flex;
        -ms-display: flex;
        -o-display: flex;
        display: flex;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    .swiper-backface-hidden .swiper-slide {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .swiper-slide {
        -webkit-flex-shrink: 0;
        -ms-flex-shrink: 0;
        -moz-flex-shrink: 0;
        flex-shrink: 0;
    }
`;

export default GlobalStyle;
