import { useEffect, useState } from 'react';

export const useCurrentUrl = () => {
    const [currentURL, setCurrentURL] = useState('');

    useEffect(() => {
        setCurrentURL(globalThis.location?.href || '');
    }, []);

    return currentURL;
};
