import React from 'react';
import Link from 'next/link';
import { MenuLinkTitleI } from '@/interfaces/index';
import { useRouter } from 'next/router';

const PotentiallyLink = ({ href, text, i }: MenuLinkTitleI) => {
    const { pathname } = useRouter();

    return (
        <>
            {href && pathname !== href ? (
                <Link href={href} passHref prefetch={false} key={i}>
                    <a>{text}</a>
                </Link>
            ) : (
                <>{text}</>
            )}
        </>
    );
};
export default PotentiallyLink;
