const colors = {
    white: '#FFFFFF',
    black: '#000000',
    black2: '#19191A',
    black3: '#1c293a',
    black4: '#111111',
    blue: '#008EDE',
    blue2: '#008AAB',
    blue3: '#99B6D8',
    blue4: '#448CCB',
    blue5: '#223C6F',
    blue6: '#047CC0',
    gray: '#ECF1F7',
    gray2: '#F6F9FC',
    gray3: '#F4F9FE',
    gray4: '#C9C8C7',
    gray5: '#A8A8A8',
    gray6: '#ECF1F6',
    red: '#FF3030',
    red2: '#E45555',
    maskColor1: '#ecf1f6',
    yellow: '#FFD200',
    orange: '#F47500',
    yellow2: '#FED230',
    yellow3: '#FFCF52',
    orange2: '#E9862A',
    darkBlue: '#004781',
    green: '#28B056',
    grayish: '#808080',
    turquoise: '#2B9EB5',
    turquoise2: '#096679'
};

export type Colors = keyof typeof colors;

type ColorsKeys = {
    [key in Colors]: key;
};

export type BasicBackgrounds = keyof Pick<ColorsKeys, 'white' | 'gray2'>;
export type TextColors = keyof Pick<ColorsKeys, 'white' | 'black'>;

export default colors;
