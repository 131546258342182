import { color, mediaBreakpointUp, cols, hover, vw } from '@/style/mixins';
import styled from 'styled-components';

export const Container = styled.div`
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    padding: 118px 20px 20px;
    font-family: Arial, sans-serif;
    background-color: ${color('blue')};
    color: ${color('white')};
    transition: opacity 0.25s;

    &.not-supported.exit {
        opacity: 1;
    }

    &.not-supported.exit-active {
        opacity: 0;
    }

    ${mediaBreakpointUp('md')} {
        padding: 118px 40px 20px;
    }

    ${mediaBreakpointUp('xl')} {
        padding: 126px ${cols(1)} 20px;
    }

    ${mediaBreakpointUp('xxl')} {
        padding: 144px ${cols(1)} 20px;
    }

    ${mediaBreakpointUp('fhd')} {
        padding: 190px ${vw(160)} 20px;
    }

    button {
        font-family: Arial, sans-serif;
    }
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 30px;
    line-height: 1;

    ${mediaBreakpointUp('md')} {
        font-size: 36px;
    }

    ${mediaBreakpointUp('xxl')} {
        font-size: 42px;
    }

    ${mediaBreakpointUp('fhd')} {
        font-size: 56px;
    }
`;

export const Description = styled.div`
    font-size: 14px;
    line-height: 1.3;

    * + & {
        margin-top: 24px;
    }

    ${mediaBreakpointUp('xl')} {
        margin-top: 26px;
        font-size: 13px;
    }

    ${mediaBreakpointUp('xxl')} {
        margin-top: 30px;
        font-size: 15px;
    }

    ${mediaBreakpointUp('fhd')} {
        margin-top: 35px;
        font-size: 20px;
    }

    & > p + p {
        margin-top: 0.7em;
    }
`;

export const BrowserList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-left: -15px;
    padding: 0;
    list-style: none;

    ${mediaBreakpointUp('md')} {
        margin-left: -14px;
    }

    ${mediaBreakpointUp('xxl')} {
        margin-left: -16px;
    }

    ${mediaBreakpointUp('fhd')} {
        margin-left: -20px;
    }

    * > & {
        margin-top: 9px;
    }
`;

export const BrowserListItem = styled.li`
    margin-top: 15px;
    margin-left: 15px;
    width: calc(50% - 15px);

    ${mediaBreakpointUp('md')} {
        margin-top: 14px;
        margin-left: 14px;
        min-width: 110px;
        width: auto;
    }

    ${mediaBreakpointUp('xxl')} {
        margin-top: 16px;
        margin-left: 16px;
    }

    ${mediaBreakpointUp('fhd')} {
        min-width: 140px;
        margin-top: 26px;
        margin-left: 20px;
    }
`;

export const BrowserListLink = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.4;
    font-size: 13px;
    text-align: center;
    height: 100px;
    padding: 10px;
    border: 1px solid ${color('white', 0.2)};
    border-radius: 4px;
    transition: opacity 0.3s ease;

    ${hover(`opacity: 0.5`)};

    ${mediaBreakpointUp('xl')} {
        font-size: 12px;
    }

    ${mediaBreakpointUp('xxl')} {
        font-size: 13px;
    }

    ${mediaBreakpointUp('fhd')} {
        height: 120px;
        font-size: 16px;
    }

    & > svg {
        width: 20px;
        height: 20px;

        ${mediaBreakpointUp('fhd')} {
            width: 24px;
            height: 24px;
        }
    }
`;

export const BrowserListLinkTitle = styled.h2`
    * > & {
        margin-top: 16px;
    }
`;
